import { compact } from 'lodash-es';
import { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Checkbox, Drawer, FormControlLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { DeliveryTicketWithIndex, DocsType } from '../../../libs';
import { EXPORT_DOCUMENTS_MUTATION, UPDATE_DELIVERY_TICKETS_DOWNLOADED_AT_MUTATION } from '../../../libs/graphQL';
import { createAutoDownloadLinkFromArrayOfUrls } from '../../../libs/utils/helperFunctions';
import ColumnName from '../ColumnAttr/ColumnAttr';
import TicketCollection from './TicketCollection/TicketCollection';

interface ExportProps {
	deliveryTickets: DeliveryTicketWithIndex[] & { autoApprove?: boolean };
}

interface DeliveryTicketsObject {
	[userId: string]: DeliveryTicketWithIndex[];
}

const getDeliveriesMap = (deliveryTickets: DeliveryTicketWithIndex[], selectedCards: string[]) =>
	compact(
		deliveryTickets
			.filter((deliveryTicket) => selectedCards.includes(deliveryTicket.id))
			.map((deliveryTicket) => deliveryTicket.data?.deliveryId)
	);

const Export = ({ deliveryTickets }: ExportProps) => {
	const theme = useTheme();
	const [isOpened, setIsOpened] = useState(false);
	const [shiftFilter, setShiftFilter] = useState('all');
	const [filtersCheck, setFiltersCheck] = useState({ CXRequest: true, customerRequest: true });
	const [selectedCards, setSelectedCards] = useState<string[]>([]);
	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [requestTypefiltersMenuAnchorEl, setRequestTypefiltersMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [updateDeliveryTicketsDownloadedAt, { loading: isLoading }] = useMutation(UPDATE_DELIVERY_TICKETS_DOWNLOADED_AT_MUTATION);
	const [downloadedTypeFiltersMenuAnchorEl, setDownloadedTypeFiltersMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isDownloadedFilterMenuOpened = Boolean(downloadedTypeFiltersMenuAnchorEl);

	const [downloadFilter, setDownloadFilter] = useState('all');
	const [exportDocuments, { loading }] = useMutation(EXPORT_DOCUMENTS_MUTATION);
	const appContext = useContext(AppContext);
	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);
	const isRequestTypeFilterMenuOpened = Boolean(requestTypefiltersMenuAnchorEl);

	useEffect(() => {
		setSelectedCards([]);
	}, [shiftFilter, filtersCheck, downloadFilter]);

	const modifiedDeliveryTicketsObject: DeliveryTicketsObject = useMemo(() => {
		const deliveryTicketsObject: DeliveryTicketsObject = {};

		const filteredDeliveryTickets = deliveryTickets
			.filter((delivery) =>
				shiftFilter === 'all'
					? delivery
					: (delivery.data?.addressTicketData?.newDeliveryTime
							? delivery.data?.addressTicketData.newDeliveryTime
							: delivery.data?.deliveryTime) === shiftFilter
			)
			.filter((delivery) =>
				downloadFilter === 'all' ? delivery : downloadFilter === 'downloaded' ? delivery.downloadedAt : !delivery.downloadedAt
			)
			.filter((ticket) =>
				filtersCheck.CXRequest ? (filtersCheck.customerRequest ? ticket : !ticket.data?.autoApprove) : ticket.data?.autoApprove
			);

		for (const ticket of filteredDeliveryTickets) {
			const existingTicket = deliveryTicketsObject[ticket.data?.userId ?? ''];
			if (existingTicket) {
				existingTicket.unshift(ticket);
			} else {
				deliveryTicketsObject[ticket.data?.userId ?? ''] = [ticket];
			}
		}

		return deliveryTicketsObject;
	}, [deliveryTickets, shiftFilter, filtersCheck.CXRequest, filtersCheck.customerRequest, downloadFilter]);

	const selectedAllCards = Object.keys(modifiedDeliveryTicketsObject).length === selectedCards.length;

	const handleSelectedTickets = (id: string, isSelected: boolean) => {
		if (isSelected) {
			setSelectedCards((prevCards) => [...prevCards, id]);
		} else {
			setSelectedCards((prevCards) => prevCards.filter((userId) => userId !== id));
		}
	};

	const handleSelectAll = () => {
		if (selectedAllCards) {
			setSelectedCards([]);
		} else {
			setSelectedCards(Object.keys(modifiedDeliveryTicketsObject));
		}
	};

	const handleExportInvoices = async () => {
		const filteredObj: DeliveryTicketsObject = {};
		for (const id of selectedCards) {
			if (modifiedDeliveryTicketsObject[id]) {
				filteredObj[id] = modifiedDeliveryTicketsObject[id];
			}
		}
		const cardIdsToBeExtracted = Object.values(filteredObj).map((array) => array[0].id);
		const downloadedInvoiceIds = Object.values(filteredObj).flatMap((array) => array.map((ticket) => ticket.id));

		const ids = getDeliveriesMap(deliveryTickets, cardIdsToBeExtracted);
		const docsTypes = [DocsType.invoice];
		const downloaded = await exportDocuments({
			variables: { ids, docTypes: docsTypes },
			onCompleted: (data) => {
				if (data?.exportDocuments.data && data?.exportDocuments.data.length > 0) {
					createAutoDownloadLinkFromArrayOfUrls(data.exportDocuments.data);
					setSelectedCards([]);
				} else {
					toast.error('Something went wrong');
				}
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
		if (downloaded) {
			await updateDeliveryTicketsDownloadedAt({
				variables: { ids: downloadedInvoiceIds, kitchen: deliveryTickets[0].kitchen },
				onError: (e: any) => {
					console.log(e);
				}
			});
		}
		setIsOpened(false);
	};

	return (
		<>
			<Stack direction="row" justifyContent="center" alignItems="center" sx={{ ml: 2 }}>
				<LoadingButton
					loading={loading || isLoading || isOpened}
					disabled={deliveryTickets.length === 0 || appContext.isOffline}
					onClick={() => setIsOpened(true)}
					variant="outlined"
					sx={{
						'&:hover': {
							backgroundColor: 'transparent',
							color: caloTheme.palette.neutral900,
							borderColor: caloTheme.palette.neutral400
						},
						textTransform: 'capitalize',
						fontSize: '16px',
						maxHeight: '40px',
						color: caloTheme.palette.neutral900,
						borderColor: caloTheme.palette.neutral900,
						borderWidth: '1.5px',
						width: '230px'
					}}
					startIcon={<DownloadRoundedIcon />}
				>
					Download Invoices
				</LoadingButton>
			</Stack>
			<Drawer
				anchor="right"
				open={isOpened}
				onClose={() => setIsOpened(false)}
				PaperProps={{
					sx: {
						width: '80%',
						padding: 2,
						backgroundColor: theme.palette.background.paper,
						boxShadow: theme.shadows[4]
					}
				}}
			>
				<DialogTitle id="alert-dialog-title" sx={{ textAlign: 'start', mt: 1, pb: 0 }}>
					<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '28px', lineHeight: '40px' }}>Download Invoices</Box>
					<Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ my: 2 }}>
						<Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ mb: 1 }}>
							<Button
								onClick={(event) => {
									!shiftMenuAnchorEl && setShiftMenuAnchorEl(event.currentTarget);
								}}
								variant="outlined"
								startIcon={<ScheduleIcon />}
								endIcon={<ExpandMoreIcon />}
								sx={{
									'&:hover': {
										backgroundColor: 'transparent',
										color: caloTheme.palette.neutral900,
										borderColor: caloTheme.palette.neutral400
									},
									textTransform: 'capitalize',
									fontSize: '16px',
									maxHeight: '40px',
									color: caloTheme.palette.neutral900,
									borderColor: caloTheme.palette.neutral900,
									borderWidth: '1.5px',
									width: 124
								}}
							>
								{shiftFilter}
								<Select
									id="demo-simple-select"
									value={shiftFilter}
									open={isShiftMenuOpened}
									onClose={() => setShiftMenuAnchorEl(null)}
									label="session"
									sx={{ visibility: 'hidden', width: 0, height: 0, mt: 7 }}
								>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'all'}
										onClick={() => {
											setShiftFilter('all');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>All</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'earlyMorning'}
										onClick={() => {
											setShiftFilter('earlyMorning');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Early Morning Shift</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'morning'}
										onClick={() => {
											setShiftFilter('morning');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Morning Shift</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'evening'}
										onClick={() => {
											setShiftFilter('evening');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Evening Shift</Box>
									</MenuItem>
								</Select>
							</Button>
							<Button
								onClick={(event) => {
									!requestTypefiltersMenuAnchorEl && setRequestTypefiltersMenuAnchorEl(event.currentTarget);
								}}
								variant="outlined"
								endIcon={<ExpandMoreIcon />}
								sx={{
									textTransform: 'capitalize',
									fontSize: '16px',
									mr: 2,
									color: caloTheme.palette.neutral900,
									maxHeight: '40px',
									borderColor: caloTheme.palette.neutral900,
									borderWidth: '1.5px',
									width: '179px',
									'&:hover': {
										backgroundColor: 'transparent',
										color: caloTheme.palette.neutral900,
										borderColor: caloTheme.palette.neutral400
									}
								}}
							>
								{filtersCheck.CXRequest ? (filtersCheck.customerRequest ? 'All REQUESTS' : 'CX REQUESTS') : 'CUSTOMER REQUESTS'}
								<Select
									id="demo-simple-select"
									open={isRequestTypeFilterMenuOpened}
									onClose={() => setRequestTypefiltersMenuAnchorEl(null)}
									label="session"
									sx={{ visibility: 'hidden', width: 0, height: 0, mt: 7 }}
								>
									<MenuItem sx={{ fontWeight: 600 }}>
										<FormControlLabel
											control={
												<Checkbox
													checked={filtersCheck.customerRequest}
													disabled={filtersCheck.customerRequest && !filtersCheck.CXRequest}
													onChange={() => setFiltersCheck({ ...filtersCheck, customerRequest: !filtersCheck.customerRequest })}
												/>
											}
											label="Customer Requests"
										/>
									</MenuItem>
									<MenuItem sx={{ fontWeight: 600 }}>
										<FormControlLabel
											control={
												<Checkbox
													checked={filtersCheck.CXRequest}
													disabled={!filtersCheck.customerRequest && filtersCheck.CXRequest}
													onChange={() => setFiltersCheck({ ...filtersCheck, CXRequest: !filtersCheck.CXRequest })}
												/>
											}
											label="CX Requests"
										/>
									</MenuItem>
								</Select>
							</Button>

							<Button
								onClick={(event) => {
									!downloadedTypeFiltersMenuAnchorEl && setDownloadedTypeFiltersMenuAnchorEl(event.currentTarget);
								}}
								variant="outlined"
								endIcon={<ExpandMoreIcon />}
								sx={{
									textTransform: 'capitalize',
									fontSize: '16px',
									mr: 2,
									color: caloTheme.palette.neutral900,
									maxHeight: '40px',
									borderColor: caloTheme.palette.neutral900,
									borderWidth: '1.5px',
									'&:hover': {
										backgroundColor: 'transparent',
										color: caloTheme.palette.neutral900,
										borderColor: caloTheme.palette.neutral400
									}
								}}
							>
								{downloadFilter}
								<Select
									id="demo-simple-select"
									value={downloadFilter}
									open={isDownloadedFilterMenuOpened}
									onClose={() => setDownloadedTypeFiltersMenuAnchorEl(null)}
									label="session"
									sx={{ visibility: 'hidden', width: 0, height: 0, mt: 7 }}
								>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'all'}
										onClick={() => {
											setDownloadFilter('all');
											setDownloadedTypeFiltersMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>All</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'downloaded'}
										onClick={() => {
											setDownloadFilter('downloaded');
											setDownloadedTypeFiltersMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Downloaded</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'not Downloaded'}
										onClick={() => {
											setDownloadFilter('not Downloaded');
											setDownloadedTypeFiltersMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Not Downloaded</Box>
									</MenuItem>
								</Select>
							</Button>
						</Stack>

						<Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ mb: 1 }}>
							<LoadingButton
								loading={loading}
								variant="contained"
								disabled={selectedCards.length === 0 || appContext.isOffline}
								sx={{
									width: 172,
									height: 42,
									borderRadius: '4px'
								}}
								onClick={handleExportInvoices}
							>
								Download
							</LoadingButton>
						</Stack>
					</Stack>
					<IconButton
						aria-label="close"
						onClick={() => {
							setIsOpened(false);
							setSelectedCards([]);
						}}
						sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
					>
						<CloseIcon />
					</IconButton>
					<Box
						display={'flex'}
						flexDirection={'row'}
						justifyContent="space-between"
						alignItems="center"
						sx={{
							mb: 2,
							width: '100%',
							minHeight: '50px',
							backgroundColor: theme.palette.neutral50,
							mt: 4,
							borderBottom: `2px solid ${theme.palette.neutral100}`
						}}
					>
						<Checkbox checked={selectedAllCards} onChange={handleSelectAll} sx={{ ml: 1 }} />
						<Typography
							sx={{
								ml: 2,
								fontSize: 14,
								fontWeight: 400,
								lineHeight: '14px',
								width: '25%',
								justifyContent: 'start',
								display: 'flex'
							}}
						>
							Type
						</Typography>
						<ColumnName sx={{ width: '20%' }}>Customer Name</ColumnName>
						<ColumnName sx={{ width: '15%' }}>Phone Number</ColumnName>
						<ColumnName sx={{ width: '13%' }}>Invoice</ColumnName>
						<ColumnName sx={{ width: '15%' }}>Driver</ColumnName>
						<ColumnName sx={{ width: '12%' }}>Shift</ColumnName>
						<ColumnName sx={{ width: '5%' }}></ColumnName>
					</Box>
				</DialogTitle>

				<DialogContent sx={{ width: '100%' }}>
					<Stack direction="column" justifyContent="space-evenly" alignItems="center" spacing={2} sx={{ mb: 1 }}>
						{Object.values(modifiedDeliveryTicketsObject)
							.reverse()
							.map((ticketCollection, index) => (
								<TicketCollection
									key={(ticketCollection?.[0].data?.userId ?? '') + index}
									ticketCollection={ticketCollection}
									isSelected={selectedCards.includes(ticketCollection?.[0].data?.userId ?? '')}
									selectedCards={selectedCards}
									handleTicketClick={handleSelectedTickets}
								/>
							))}
					</Stack>
				</DialogContent>
			</Drawer>
		</>
	);
};
export default Export;
