import { useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';

import { Food, Kitchen } from '../libs';
import {
	GraphQLClient,
	LIST_FOODS_QUERY,
	SUBSCRIBE_TO_BATCH_UPDATE_FOODS_PORTIONING_STATUS_SUBSCRIPTION,
	SUBSCRIBE_TO_UPDATE_FOOD_PORTIONING_SUBSCRIPTION
} from '../libs/graphQL';

type UseListPaginatedFoodsQueryProps = {
	kitchen: Kitchen;
	date: string;
	shift?: string;
	brand?: string;
	limit?: number;
	next?: string;
	fetchAll?: boolean;
};

const useListPaginatedFoodsQuery = ({
	kitchen,
	date,
	shift,
	brand,
	limit = 500,
	next,
	fetchAll = false
}: UseListPaginatedFoodsQueryProps) => {
	const [foods, setFoods] = useState<Food[]>([]);
	const [newNext, setNewNext] = useState<string | undefined>(next);

	const fetchAllData = async (nextToken: string) => {
		try {
			const result = await fetchMore({
				variables: { next: nextToken }
			});
			const moreFoods = result.data?.listFoods?.data || [];
			const nextNextToken = result.data?.listFoods?.next;

			setFoods((prev) => [...prev, ...moreFoods]);
			setNewNext(nextNextToken || undefined);

			if (nextNextToken) {
				await fetchAllData(nextNextToken);
			}
		} catch (error) {
			console.error('Error fetching more foods:', error);
			toast.error('Error fetching additional foods');
		}
	};

	const handleDataFetch = async (data: any) => {
		const currentFoods = data?.listFoods?.data || [];
		setFoods(currentFoods);

		const nextToken = data?.listFoods?.next;
		setNewNext(nextToken || undefined);

		if (fetchAll && nextToken) {
			await fetchAllData(nextToken);
		}
	};

	const { loading, refetch, fetchMore } = useQuery(LIST_FOODS_QUERY, {
		variables: { kitchen, date, shift, brand, limit },
		onCompleted: handleDataFetch,
		onError: (error) => {
			console.log('error', error);
			toast.error(error.message);
		}
	});

	const refetchFoods = async () => {
		const result = await refetch();
		await handleDataFetch(result.data);
		return result;
	};

	useSubscription(SUBSCRIBE_TO_UPDATE_FOOD_PORTIONING_SUBSCRIPTION);
	useSubscription(SUBSCRIBE_TO_BATCH_UPDATE_FOODS_PORTIONING_STATUS_SUBSCRIPTION);

	const clearData = () => {
		setFoods([]);
		// eslint-disable-next-line unicorn/no-useless-undefined
		setNewNext(undefined);
		GraphQLClient.clearStore();
	};

	return {
		foodsLoading: loading,
		foods,
		refetchFoods,
		hasNext: Boolean(newNext),
		clearFoodsData: clearData
	};
};

export default useListPaginatedFoodsQuery;
