import { useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Icon } from '@iconify/react';
import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Modal from '../../../components/Modal';
import PortionActionButton from '../../../components/PortionActionButton/index';
import { Food, FoodPortioningFoodType, Page, Session, UpdateFoodPortioningStatusOperation } from '../../../libs';
import { UPDATE_FOOD_PORTIONING_STATUS_MUTATION } from '../../../libs/graphQL';
import { getFoodPortioningStatusAttributes, isValidURL } from '../../../libs/utils/helperFunctions';
import AssemblyStatusItem from '../AssemblyStatusItem/index';
import ProcessTime from '../ProcessTime';

interface AssemblyCheckTableRowProps {
	food: Food;
	foodType: FoodPortioningFoodType;
	shift: string;
}

const AssemblyCheckTableRow = ({ food, foodType, shift }: AssemblyCheckTableRowProps) => {
	const theme = useTheme();

	const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);

	const [mutateFoodPortioningStatus, { loading }] = useMutation(UPDATE_FOOD_PORTIONING_STATUS_MUTATION);
	const shiftValues = shift === Session.evening ? [Session.evening, Session.earlyMorning] : [shift];

	const portioningStatus = food.portioningStatus?.find((s) => shiftValues.includes(s.session as Session));
	const attributes = getFoodPortioningStatusAttributes(portioningStatus!, foodType, Page.assemblyCheck, theme);

	const handleUpdateFoodPortioningStatus = async (operation: UpdateFoodPortioningStatusOperation) => {
		if (!loading) {
			await mutateFoodPortioningStatus({
				variables: {
					id: food.id,
					session: shift,
					kitchen: food.kitchen,
					operation: operation
				},
				onError: (e) => {
					toast.error(e.message);
				}
			});
		}
	};

	return (
		<TableRow>
			<TableCell sx={{ border: 'none', p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography padding="6px" fontWeight="600" fontSize="19px" textTransform={'capitalize'}>
					{food.name.en}
				</Typography>
				<IconButton
					onClick={() => {
						food.assemblyURL && window.open(food.assemblyURL, '_blank');
					}}
					disabled={!food.assemblyURL || !isValidURL(food.assemblyURL)}
					sx={{ color: '#367bf5', marginRight: '140px' }}
				>
					<Icon icon="ph:file-text-duotone" width="1.2em" height="1.3em" />
				</IconButton>
			</TableCell>
			<TableCell sx={{ border: 'none', p: 1 }}>
				<Stack flexDirection="row" gap="15px">
					<AssemblyStatusItem attributes={attributes} />
					{attributes.time && <ProcessTime attributes={attributes} portioningStatus={portioningStatus} time={attributes.time!} />}
				</Stack>
			</TableCell>
			<TableCell sx={{ border: 'none', p: 1 }}>
				<PortionActionButton
					attributes={attributes}
					loading={loading}
					handleUpdateFoodPortioningStatus={handleUpdateFoodPortioningStatus}
				/>
			</TableCell>
			<Modal
				loading={loading}
				open={isConfirmationModalOpened}
				setOpen={(isOpened: boolean) => setIsConfirmationModalOpened(isOpened)}
				handleClose={() => setIsConfirmationModalOpened(false)}
				handleAccept={() => handleUpdateFoodPortioningStatus(UpdateFoodPortioningStatusOperation.notReadyToBePortioned)}
				title={'Uncheck ' + food.name.en}
				message={'Are you sure you want to uncheck ' + food.name.en + '?'}
			/>
		</TableRow>
	);
};

export default AssemblyCheckTableRow;
