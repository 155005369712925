import { startCase } from 'lodash-es';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { AddressServiceClass } from '@calo/services';
import { NewDeliveryAddress } from '@calo/types';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Chip, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import ConfirmationMessage from '../../../components/ConfirmationMessage';
import { Brand, Country, DeliveryTicketData, DeliveryTicketStatus, DeliveryTicketType, Kitchen, User } from '../../../libs';
import { APPROVE_DELIVERY_TICKET_MUTATION, REJECT_DELIVERY_TICKET_MUTATION } from '../../../libs/graphQL';
import { getUserAttributes } from '../../../libs/utils/cognitoUserHelpers';
import { formatDate } from '../../../libs/utils/helperFunctions';
import ColumnAttr from '../ColumnAttr/ColumnAttr';
import { getStatusColor } from './helpers';

type SwapCardProps = {
	id: string;
	data?: DeliveryTicketData;
	date?: string;
	createdBy?: User;
	resolvedBy?: User;
	resolvedAt?: string;
	downloadedAt?: string;
	kitchen: Kitchen;
	country: Country;
	brand: Brand;
	status?: DeliveryTicketStatus;
	createdAt?: string;
	index?: number;
	zone?: string;
	isMutationRunning: boolean;
	setIsMutationRunning: (data: boolean) => void;
} & (
	| {
			tabValue: 1;
			numberOfTickets: number;
			isCardOpened: boolean;
			isLastItem: boolean;
			setOpenedCardId: (id: string | null) => void;
	  }
	| { tabValue: 0; day: string }
);

const cancelClickEvent = (event: React.MouseEvent) => event.stopPropagation();

const SwapCard = ({
	id,
	status,
	kitchen,
	data,
	createdBy,
	resolvedBy,
	index,
	zone,
	resolvedAt,
	createdAt,
	isMutationRunning,
	downloadedAt,
	setIsMutationRunning,
	...props
}: SwapCardProps) => {
	const [isOpened, setIsOpened] = useState(false);
	const [isTicketOpened, setIsTicketOpened] = useState(props.tabValue === 0 ? true : false);
	const [isPreviousAddressHidden, setIsPreviousAddressHidden] = useState(data?.type === DeliveryTicketType.address);
	const [action, setAction] = useState(true);
	const [confirmationMessage, setConfirmationMessage] = useState('Are you sure you want to approve this change?');
	const appContext = useContext(AppContext);
	const theme = useTheme();

	const [approveDeliveryTicket, { loading: approveLoading }] = useMutation(APPROVE_DELIVERY_TICKET_MUTATION);
	const [rejectDeliveryTicket, { loading: rejectLoading }] = useMutation(REJECT_DELIVERY_TICKET_MUTATION);

	const isSecondTab = props.tabValue === 1;

	const AddressService = new AddressServiceClass();
	const oldAddress = data?.addressTicketData?.oldAddress
		? AddressService.display(data?.addressTicketData?.oldAddress as unknown as NewDeliveryAddress)
		: '';
	const newAddress = data?.addressTicketData?.newAddress
		? AddressService.display(data?.addressTicketData?.newAddress as unknown as NewDeliveryAddress)
		: '';

	const oldAddressMapLinkClickHandler = () => {
		if (data?.addressTicketData?.newAddress?.lat && data?.addressTicketData?.newAddress?.lng)
			window.open(
				'https://maps.google.com?q=' + data?.addressTicketData?.newAddress?.lat + ',' + data?.addressTicketData?.newAddress?.lng
			);
	};

	const newAddressMapLinkClickHandler = () => {
		if (data?.addressTicketData?.oldAddress?.lat && data?.addressTicketData?.oldAddress?.lng)
			window.open(
				'https://maps.google.com?q=' + data?.addressTicketData?.oldAddress?.lat + ',' + data?.addressTicketData?.oldAddress?.lng
			);
	};

	const handleApproveDeliveryTicket = async () => {
		setIsMutationRunning(true);
		await approveDeliveryTicket({
			variables: { id: id, kitchen: kitchen, type: data?.type, user: getUserAttributes(appContext.user) },
			onCompleted: () => {
				appContext.refetchFoods();
				setIsMutationRunning(false);
				toast.success('Ticket approved successfully');
			},
			onError: (e) => {
				toast.error(e.message);
				setIsMutationRunning(false);
			}
		});
	};

	const handleRejectDeliveryTicket = async () => {
		setIsMutationRunning(true);
		await rejectDeliveryTicket({
			variables: { id: id, kitchen: kitchen, type: data?.type, user: getUserAttributes(appContext.user) },
			onCompleted: () => {
				appContext.refetchFoods();
				setIsMutationRunning(false);
				toast.success('Ticket rejected successfully');
			},
			onError: (e) => {
				toast.error(e.message);
				setIsMutationRunning(false);
			}
		});
	};

	const cardClickHandler = () => {
		if (isSecondTab && props.isCardOpened) {
			props.setOpenedCardId(null);
		} else if (isSecondTab && !props.isCardOpened) {
			props.setOpenedCardId(id);
		} else {
			setIsTicketOpened(!isTicketOpened);
		}
	};

	return (
		<Card
			sx={{
				minWidth: '100%',
				boxShadow: 0,
				maxHeight: 'auto',
				borderRadius: '0px',
				mb: isSecondTab ? 0 : 2
			}}
			onClick={cardClickHandler}
		>
			<CardContent>
				<Stack direction={'row'} justifyContent={'space-between'} alignItems="left">
					<ColumnAttr sx={{ width: '13%' }}>
						#{index} {data?.type === DeliveryTicketType.cancel ? 'Cancel Delivery' : 'Logistics Change'}
					</ColumnAttr>
					<ColumnAttr sx={{ width: '13%', justifyContent: 'left' }}>{data?.userName ?? '--'}</ColumnAttr>
					<ColumnAttr sx={{ width: '10%', justifyContent: 'left' }}>{data?.phoneNumber} </ColumnAttr>
					<ColumnAttr sx={{ width: '10%', justifyContent: 'left' }}>{data?.deliveryOldShortId || 'xxx-xxx'}</ColumnAttr>
					<ColumnAttr sx={{ width: '10%', justifyContent: 'left' }}>{data?.deliveryShortId || 'xxx-xxx'}</ColumnAttr>
					<ColumnAttr sx={{ width: '10%', justifyContent: 'left' }}>{zone}</ColumnAttr>

					<ColumnAttr sx={{ width: '10%', justifyContent: 'left' }}>
						{status && (
							<Chip
								label={startCase(status)}
								sx={{
									backgroundColor: getStatusColor(status),
									fontSize: '1rem',
									padding: '10px'
								}}
							/>
						)}
					</ColumnAttr>

					<ColumnAttr sx={{ width: '15%', justifyContent: 'left' }}>
						<Stack flexDirection={'row'} justifyContent={'flex-end'} textAlign={'end'}>
							{downloadedAt && (
								<CheckCircleRoundedIcon sx={{ color: caloTheme.palette.success.main, fontSize: 30, marginRight: 2 }} />
							)}
							{data?.autoApprove ? (
								<PersonOutlineRoundedIcon sx={{ color: caloTheme.palette.primary.main, fontSize: 30, marginRight: 2 }} />
							) : (
								<SupportAgentRoundedIcon sx={{ color: caloTheme.palette.primary.main, fontSize: 30, marginRight: 2 }} />
							)}
							{isSecondTab &&
								(props.isCardOpened ? (
									<KeyboardArrowUpRoundedIcon
										onClick={() => props.setOpenedCardId(null)}
										sx={{ color: caloTheme.palette.primary.main, fontSize: 30 }}
									/>
								) : (
									<KeyboardArrowDownRoundedIcon
										onClick={() => props.setOpenedCardId(id)}
										sx={{ color: caloTheme.palette.primary.main, fontSize: 30 }}
									/>
								))}
							{!isSecondTab &&
								(isTicketOpened ? (
									<KeyboardArrowUpRoundedIcon
										onClick={() => setIsTicketOpened(!isTicketOpened)}
										sx={{ color: caloTheme.palette.primary.main, fontSize: 30 }}
									/>
								) : (
									<KeyboardArrowDownRoundedIcon
										onClick={() => setIsTicketOpened(!isTicketOpened)}
										sx={{ color: caloTheme.palette.primary.main, fontSize: 30 }}
									/>
								))}
						</Stack>
					</ColumnAttr>
				</Stack>
				{(isSecondTab ? props.isCardOpened : isTicketOpened) && (
					<>
						<Stack
							sx={{
								backgroundColor: caloTheme.palette.grey[100],
								mt: 2,
								p: 2,
								borderBottom: `3px solid ${caloTheme.palette.grey[400]}`,
								mb: -3,
								mx: -2
							}}
						>
							<Stack
								direction={'column'}
								justifyContent="space-between"
								alignItems="start"
								sx={{
									my: '1px',
									display: data?.type === DeliveryTicketType.cancel || data?.addressTicketData?.newAddress ? 'initial' : 'none'
								}}
								className="stop-click"
								onClick={cancelClickEvent}
							>
								{data?.type === DeliveryTicketType.address && (
									<Box sx={{ p: 1, backgroundColor: theme.palette.white, my: 1, borderRadius: '4px' }}>
										<Typography component="div" sx={{ fontSize: 12, fontWeight: 500, lineHeight: '14px' }}>
											New Location:
										</Typography>
										<Stack direction={'row'} justifyContent="space-between" alignItems="center">
											<Typography component="div" sx={{ fontSize: 19, fontWeight: 500, lineHeight: '23px' }}>
												{newAddress}
											</Typography>
											<IconButton style={{ marginTop: '-12px', marginRight: 8 }} onClick={newAddressMapLinkClickHandler}>
												<LocationOnOutlinedIcon style={{ color: caloTheme.palette.primary500 }} />
											</IconButton>
										</Stack>
									</Box>
								)}
								{isPreviousAddressHidden ? (
									<>
										{data?.type === DeliveryTicketType.address && (
											<Stack direction={'row'} justifyContent="center" alignItems="center">
												<Button
													variant="text"
													onClick={() => setIsPreviousAddressHidden(false)}
													sx={{ fontSize: 16, fontWeight: 500, lineHeight: '19px', color: theme.palette.primary500 }}
												>
													Show Old Address
												</Button>
											</Stack>
										)}
									</>
								) : (
									<>
										<Stack
											direction={'column'}
											justifyContent="space-between"
											alignItems="start"
											spacing={1}
											sx={{ mb: 1, p: 1, backgroundColor: theme.palette.white, borderRadius: '4px' }}
										>
											<Typography component="div" sx={{ fontSize: 12, fontWeight: 500, lineHeight: '14px' }}>
												{data?.type === DeliveryTicketType.address ? 'Old Location' : 'Location'}:
											</Typography>
											<Stack direction={'row'} justifyContent="space-between" alignItems="center" width="100%">
												<Typography component="div" sx={{ fontSize: 19, fontWeight: 500, lineHeight: '23px' }}>
													{oldAddress}
												</Typography>
												<IconButton style={{ marginTop: '-12px', marginRight: 8 }} onClick={oldAddressMapLinkClickHandler}>
													<LocationOnOutlinedIcon style={{ color: caloTheme.palette.primary500 }} />
												</IconButton>
											</Stack>
										</Stack>
										{data?.type === DeliveryTicketType.address && (
											<Stack direction={'row'} justifyContent="center" alignItems="center">
												<Button
													variant="text"
													onClick={() => setIsPreviousAddressHidden(true)}
													sx={{ fontSize: 16, fontWeight: 500, lineHeight: '19px', color: theme.palette.primary500 }}
												>
													Hide Old Address
												</Button>
											</Stack>
										)}
									</>
								)}
							</Stack>

							<Stack
								direction={'row'}
								justifyContent={'space-between'}
								alignItems="center"
								sx={{ py: 1 }}
								onClick={cancelClickEvent}
							>
								<Stack
									direction={'row'}
									justifyContent={'space-between'}
									alignItems="start"
									width={'47%'}
									sx={{ backgroundColor: theme.palette.white, borderRadius: '4px' }}
								>
									{data?.addressTicketData?.newDriver ? (
										<>
											<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" sx={{ p: 1 }}>
												{data?.addressTicketData?.oldDriver && (
													<>
														<Typography
															component="div"
															display={'flex'}
															flexDirection="row"
															alignItems="center"
															sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 500, lineHeight: '14px' }}
														>
															Old Driver
														</Typography>
														<Typography
															component="div"
															sx={{
																marginLeft: '4px',
																mt: 1,
																textTransform: 'capitalize',
																fontSize: 19,
																fontWeight: 500,
																lineHeight: '23px',
																marginRight: '8px'
															}}
														>
															{`${data?.addressTicketData?.oldDriver.name} ${data?.addressTicketData?.oldDriver?.code ? `(#${data?.addressTicketData?.oldDriver.code})` : ''}` ||
																'---'}
														</Typography>
													</>
												)}
											</Stack>
											<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" width={'50%'} sx={{ p: 1 }}>
												<Typography
													component="div"
													display={'flex'}
													flexDirection="row"
													alignItems="center"
													sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 500, lineHeight: '14px' }}
												>
													New Driver
												</Typography>
												<Typography
													component="div"
													sx={{
														marginLeft: '4px',
														mt: 1,
														textTransform: 'capitalize',
														fontSize: 19,
														fontWeight: 500,
														lineHeight: '23px',
														marginRight: '8px'
													}}
												>
													{`${data?.addressTicketData?.newDriver.name} ${data?.addressTicketData?.newDriver?.code ? `(#${data?.addressTicketData?.newDriver.code})` : ''}` ||
														'---'}
												</Typography>
											</Stack>
										</>
									) : (
										<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" sx={{ p: 1 }}>
											<Typography
												component="div"
												display={'flex'}
												flexDirection="row"
												alignItems="center"
												sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 500, lineHeight: '14px' }}
											>
												Driver
											</Typography>
											<Typography
												component="div"
												sx={{
													marginLeft: '4px',
													mt: 1,
													textTransform: 'capitalize',
													fontSize: 19,
													fontWeight: 500,
													lineHeight: '23px',
													marginRight: '8px'
												}}
											>
												{`${data?.addressTicketData?.oldDriver?.name} ${data?.addressTicketData?.oldDriver?.code ? `(#${data?.addressTicketData?.oldDriver.code})` : ''}` ||
													'---'}
											</Typography>
										</Stack>
									)}
								</Stack>

								<Stack
									direction={'row'}
									justifyContent={'space-between'}
									alignItems="center"
									width={'51%'}
									sx={{ backgroundColor: theme.palette.white, borderRadius: '4px' }}
								>
									{data?.addressTicketData?.newDeliveryTime ? (
										<>
											<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" width={'50%'} sx={{ p: 1 }}>
												{data?.deliveryTime && (
													<>
														<Typography
															component="div"
															display={'flex'}
															flexDirection="row"
															alignItems="center"
															sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 500, lineHeight: '14px' }}
														>
															Old Shift
														</Typography>
														<Typography
															component="div"
															sx={{
																marginLeft: '4px',
																textTransform: 'capitalize',
																fontSize: 19,
																fontWeight: 500,
																lineHeight: '23px',
																marginRight: '8px',
																mt: 1
															}}
														>
															{data?.deliveryTime || '---'}
														</Typography>
													</>
												)}
											</Stack>
											<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" width={'50%'}>
												<Typography
													component="div"
													display={'flex'}
													flexDirection="row"
													alignItems="center"
													sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 500, lineHeight: '14px' }}
												>
													New Shift
												</Typography>
												<Typography
													component="div"
													sx={{
														marginLeft: '4px',
														textTransform: 'capitalize',
														fontSize: 19,
														fontWeight: 500,
														lineHeight: '23px',
														marginRight: '8px',
														mt: 1
													}}
												>
													{data?.addressTicketData?.newDeliveryTime || '--'}
												</Typography>
											</Stack>
										</>
									) : (
										<Stack direction={'column'} justifyContent={'space-between'} alignItems="start" width={'50%'} sx={{ p: 1 }}>
											{data?.deliveryTime && (
												<>
													<Typography
														component="div"
														display={'flex'}
														flexDirection="row"
														alignItems="center"
														sx={{ pr: 1, pt: 1, fontSize: 12, fontWeight: 500, lineHeight: '14px' }}
													>
														Shift
													</Typography>
													<Typography
														component="div"
														sx={{
															marginLeft: '4px',
															textTransform: 'capitalize',
															fontSize: 19,
															fontWeight: 500,
															lineHeight: '23px',
															marginRight: '8px',
															mt: 1
														}}
													>
														{data?.deliveryTime || '---'}
													</Typography>
												</>
											)}
										</Stack>
									)}
								</Stack>
							</Stack>

							<Stack
								justifyContent={'space-between'}
								display="flex"
								flexDirection={'row'}
								alignItems="start"
								onClick={cancelClickEvent}
								sx={{ mb: -2 }}
							>
								<Stack justifyContent={'space-between'} display="flex" flexDirection={'column'} alignItems="start" sx={{ mt: 1 }}>
									<Stack direction={'row'} justifyContent={'space-between'} alignItems="center" sx={{ mb: 1, mt: 2 }}>
										<Typography
											component="div"
											display={'flex'}
											flexDirection="row"
											sx={{ fontSize: 12, fontWeight: 500, lineHeight: '14px' }}
										>
											Created By:
										</Typography>
										<Typography component="div" sx={{ marginLeft: '4px', fontSize: 14, fontWeight: 500, lineHeight: '17px' }}>
											{startCase(createdBy?.name)} on {formatDate(createdAt ?? '', 'HH:mm dd/MM/yyyy')}
										</Typography>
									</Stack>
									{resolvedBy && resolvedAt && (
										<Stack direction={'row'} justifyContent={'space-between'} alignItems="center" sx={{ mb: 2 }}>
											<Typography
												component="div"
												display={'flex'}
												flexDirection="row"
												sx={{ fontSize: 12, fontWeight: 500, lineHeight: '14px' }}
											>
												Resolved By:
											</Typography>
											<Typography component="div" sx={{ marginLeft: '4px', fontSize: 14, fontWeight: 500, lineHeight: '17px' }}>
												{startCase(resolvedBy.name)} on {formatDate(resolvedAt, 'HH:mm dd/MM/yyyy')}
											</Typography>
										</Stack>
									)}
								</Stack>
								<Stack direction={'row'} justifyContent="end" alignItems="left">
									{(status === DeliveryTicketStatus.pending || status === DeliveryTicketStatus.approved) && (
										<LoadingButton
											loading={rejectLoading}
											variant="text"
											onClick={() => {
												if (status === DeliveryTicketStatus.pending) {
													setAction(false);
													setConfirmationMessage('Are you sure you want to reject this change?');
													setIsOpened(true);
												}
											}}
											disabled={status === DeliveryTicketStatus.approved || appContext.isOffline || isMutationRunning}
											sx={{
												textTransform: 'capitalize',
												fontSize: '19px',
												width: '150px',
												fontWeight: 500,
												color: status === DeliveryTicketStatus.approved ? theme.palette.primary500 : theme.palette.red,
												':hover': {
													color: status === DeliveryTicketStatus.approved ? theme.palette.primary500 : theme.palette.red,
													background: status === DeliveryTicketStatus.approved ? 'white' : '#FAEEEE'
												},
												':disabled': {
													color: status === DeliveryTicketStatus.approved ? theme.palette.primary500 : theme.palette.neutral600
												}
											}}
										>
											{status === DeliveryTicketStatus.approved ? 'Approved' : 'Reject'}
										</LoadingButton>
									)}
									{(status === DeliveryTicketStatus.pending || status === DeliveryTicketStatus.rejected) && (
										<LoadingButton
											loading={approveLoading}
											variant={status === DeliveryTicketStatus.rejected ? 'text' : undefined}
											onClick={() => {
												if (status === DeliveryTicketStatus.pending) {
													setAction(true);
													setConfirmationMessage('Are you sure you want to approve this change?');
													setIsOpened(true);
												}
											}}
											disabled={status === DeliveryTicketStatus.rejected || appContext.isOffline || isMutationRunning}
											sx={{
												px: 2,
												textTransform: 'none',
												mb: '10px',
												fontSize: '19px',
												fontWeight: status === DeliveryTicketStatus.rejected ? 600 : 'inherit',
												m: 2,
												color: status === DeliveryTicketStatus.rejected ? theme.palette.red : 'white',
												backgroundColor: status === DeliveryTicketStatus.rejected ? 'white' : theme.palette.primary500,
												':hover': {
													backgroundColor: status === DeliveryTicketStatus.rejected ? 'white' : theme.palette.primary600
												},
												':disabled': {
													color: status === DeliveryTicketStatus.rejected ? theme.palette.red : theme.palette.neutral600,
													backgroundColor: status === DeliveryTicketStatus.rejected ? 'white' : theme.palette.neutral100
												}
											}}
										>
											{status === DeliveryTicketStatus.rejected
												? 'Rejected'
												: data?.type === DeliveryTicketType.address
													? 'Confirm Change'
													: 'Cancel Delivery'}
										</LoadingButton>
									)}
								</Stack>
							</Stack>
						</Stack>
					</>
				)}
			</CardContent>
			<ConfirmationMessage
				isOpened={isOpened}
				message={confirmationMessage}
				accept={'Yes'}
				reject={'No'}
				handleAccept={action ? handleApproveDeliveryTicket : handleRejectDeliveryTicket}
				handleClose={() => setIsOpened(false)}
			/>
		</Card>
	);
};

export default SwapCard;
