import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { LeftoverMetricsViewTypes } from '../../../../libs';

interface FilterSectionProps {
	selectedView: LeftoverMetricsViewTypes;
	setSelectedView: React.Dispatch<React.SetStateAction<LeftoverMetricsViewTypes>>;
	handleDownload: () => Promise<any>;
	isLoading: boolean;
	openFilterDrawer: (data: boolean) => void;
	isFilterActive: boolean;
}

const FilterSection = ({
	isFilterActive,
	setSelectedView,
	selectedView,
	handleDownload,
	isLoading,
	openFilterDrawer
}: FilterSectionProps) => {
	const theme = useTheme();
	const renderLeftButtonStyling = (isDarker: boolean) => ({
		color: theme.palette.black,
		backgroundColor: isDarker ? theme.palette.grey[300] : theme.palette.white,
		borderColor: theme.palette.black,
		borderRadius: '3px',
		width: '120px',
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
			borderColor: theme.palette.black
		}
	});

	const rightButtonsStyling = {
		color: theme.palette.black,
		backgroundColor: theme.palette.white,
		borderColor: theme.palette.black,
		borderRadius: '3px',
		paddingX: '15px',
		paddingY: '3px',
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
			borderColor: theme.palette.black
		}
	};

	const activeFilterStyling = {
		color: isFilterActive ? theme.palette.primary500 : theme.palette.black,
		backgroundColor: isFilterActive ? theme.palette.primary100 : theme.palette.white,
		borderColor: isFilterActive ? theme.palette.primary500 : theme.palette.black
	};
	return (
		<Box display="flex" flexDirection="row" justifyContent={'space-between'} sx={{ width: '100%' }}>
			<Box display="flex" flexDirection="row">
				<Button
					variant="outlined"
					onClick={() => setSelectedView(LeftoverMetricsViewTypes.meals)}
					sx={{
						...renderLeftButtonStyling(selectedView === LeftoverMetricsViewTypes.meals),
						borderTopRightRadius: 0,
						borderBottomRightRadius: 0
					}}
				>
					<Typography fontWeight="500" fontSize="14px">
						MEALS
					</Typography>
				</Button>
				<Button
					variant="outlined"
					onClick={() => setSelectedView(LeftoverMetricsViewTypes.components)}
					sx={{
						...renderLeftButtonStyling(selectedView === LeftoverMetricsViewTypes.components),
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0
					}}
				>
					<Typography fontWeight="500" fontSize="14px">
						COMPONENTS
					</Typography>
				</Button>
			</Box>
			<Box display="flex" flexDirection="row">
				<Button
					startIcon={<FilterAltRoundedIcon />}
					variant="outlined"
					onClick={() => openFilterDrawer(true)}
					sx={{ ...rightButtonsStyling, marginRight: '10px', ...activeFilterStyling }}
				>
					<Typography fontWeight="500" fontSize="14px">
						FILTER
					</Typography>
				</Button>
				<Button
					disabled={isLoading}
					startIcon={<FileDownloadIcon />}
					variant="outlined"
					onClick={async () => await handleDownload()}
					sx={rightButtonsStyling}
				>
					<Typography fontWeight="500" fontSize="14px">
						DOWNLOAD
					</Typography>
				</Button>
			</Box>
		</Box>
	);
};

export default FilterSection;
