import { sortBy } from 'lodash-es';

import { Box, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import EmptyMessage from '../../../../components/EmptyMessage/EmptyMessage';
import { Chef, Session, SingleFoodComponentWithBatches } from '../../../../libs';
import { formatDate } from '../../../../libs/utils/helperFunctions';
import QualityTableBody from '../QualityTableBody/QualityTableBody';
import { StyledTableHeadCell } from './StyledTableHeadCell';

interface QualityTableProps {
	foodComponents: SingleFoodComponentWithBatches[];
	kitchen: string;
	chefs: Chef[];
	date: string;
	shift: Session;
}

const QualityTable = ({ date, kitchen, chefs, foodComponents, shift }: QualityTableProps) => {
	const shiftValues = shift === Session.evening ? [Session.evening, Session.earlyMorning] : [shift];

	const sortedComponents = sortBy(foodComponents, 'name.en');

	const filteredChefs = chefs.filter((chef) => shiftValues.includes(chef.session));
	return (
		<>
			<Box sx={{ width: '98%', ml: 2, mr: 2 }}>
				<Stack sx={{ width: '100%', mt: 0 }}>
					<Table sx={{ width: '100%' }} stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell colSpan={8} sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '18px', pl: 1, border: 0 }}>
									{formatDate(date, 'dd/MM/yyyy')}
								</TableCell>
							</TableRow>
							<TableRow>
								<StyledTableHeadCell sx={{ borderRadius: '8px 0 0 8px', maxWidth: '24%' }}>Component Name</StyledTableHeadCell>
								<StyledTableHeadCell style={{ maxWidth: '12%' }}> Batch</StyledTableHeadCell>
								<StyledTableHeadCell style={{ maxWidth: '12%' }}>Batch Quantity</StyledTableHeadCell>
								<StyledTableHeadCell style={{ maxWidth: '12%' }}> Cooked By</StyledTableHeadCell>
								<StyledTableHeadCell style={{ maxWidth: '20%' }}>Verified By</StyledTableHeadCell>
								<StyledTableHeadCell style={{ maxWidth: '20%' }} align="center">
									Quality
								</StyledTableHeadCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sortedComponents.length > 0 ? (
								<>
									{sortedComponents.map((foodComponent) => (
										<QualityTableBody
											shift={shift}
											kitchen={kitchen}
											date={date}
											key={foodComponent.id}
											foodComponent={foodComponent}
											chefs={filteredChefs}
										/>
									))}
									<TableRow>
										<TableCell colSpan={8} sx={{ border: 'none' }}>
											<EmptyMessage label={'The End'} style={{ textAlign: 'center' }} />
										</TableCell>
									</TableRow>
								</>
							) : (
								<TableRow>
									<TableCell colSpan={8} sx={{ border: 'none' }}>
										<EmptyMessage label={'No Components'} style={{ textAlign: 'center' }} />
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</Stack>
			</Box>
		</>
	);
};

export default QualityTable;
