import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled, useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { AppContext } from '../../../../App';
import { InputField } from '../../../../components';
import { FoodComponent, LeftoverAction } from '../../../../libs';
import { UPDATE_FCWASTAGE_INPUTS_MUTATION } from '../../../../libs/graphQL';
import { weightCalculator } from '../../../../libs/utils/foodComponentHelper';

interface WastageTableRowProps {
	foodComponent: FoodComponent;
	date: string;
}

const preventMinus = (e: { code: string; preventDefault: () => void }) => {
	if (e.code === 'Minus') {
		e.preventDefault();
	}
};

const WastageTableRow = ({ foodComponent, date }: WastageTableRowProps) => {
	const [actionState, setActionState] = useState(foodComponent.kitchenWastageInputs?.action || '');
	const [chilledWeightState, setChilledWeightState] = useState(foodComponent.kitchenWastageInputs?.chilledWeight);
	const [preppedWeightState, setPreppedWeightState] = useState(foodComponent.kitchenWastageInputs?.preppedWeight);
	const [wastageState, setWastageState] = useState(foodComponent.kitchenWastageInputs?.wastage);
	const [actionCommentType, setActionCommentType] = useState('select');
	const [wastageError, setWastageError] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const theme = useTheme();
	const appContext = useContext(AppContext);
	const [chilledWeightError, setChilledWeightError] = useState(false);
	const [preppedWeightError, setPreppedWeightError] = useState(false);

	const [mutateWastageTracking, { loading }] = useMutation(UPDATE_FCWASTAGE_INPUTS_MUTATION, {
		onCompleted() {
			toast.success('Updated successfully');
		},
		onError() {
			toast.error('error');
		}
	});

	const handleActionChange = (value: string) => {
		setActionCommentType('select');
		setActionState(value);
		handleUpdateAction(value);
	};

	const handleWastageChange = async (name: string, value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (
			name === 'wastage' &&
			(!foodComponent.kitchenWastageInputs?.chilledWeight || Number(value) > foodComponent.kitchenWastageInputs?.chilledWeight)
		) {
			setWastageError(true);
			toast.error('Wastage cannot be greater than the actual cooked weight');
			return;
		} else if (name === 'wastage') {
			setWastageError(false);
		}

		await mutateWastageTracking({
			variables: { id: foodComponent.id, kitchen: foodComponent.kitchen, inputs: { [name]: value ? value : null }, date }
		});
	};

	const handleUpdateAction = async (action: string) => {
		const inputs = {
			action: action
		};
		await mutateWastageTracking({
			variables: { id: foodComponent.id, kitchen: foodComponent.kitchen, inputs, date }
		});
	};

	const handleActionInputChange = (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (!value) {
			setActionCommentType('select');
			return;
		}
		setActionState(String(value));
		setActionCommentType('Other');
		handleUpdateAction(String(value));
	};

	useEffect(() => {
		if (!loading && foodComponent.kitchenWastageInputs) {
			const kitchenWastage = foodComponent.kitchenWastageInputs;
			if (kitchenWastage.chilledWeight && kitchenWastage.chilledWeight !== chilledWeightState)
				setChilledWeightState(kitchenWastage.chilledWeight);
			if (kitchenWastage.preppedWeight && kitchenWastage.preppedWeight !== preppedWeightState)
				setPreppedWeightState(kitchenWastage.preppedWeight);
			if (kitchenWastage.wastage && kitchenWastage.wastage !== wastageState) {
				setWastageState(kitchenWastage.wastage);
			}
			if (kitchenWastage.action && kitchenWastage.action !== actionState) {
				setActionState(kitchenWastage.action);
			}
		}
	}, [foodComponent]);

	const handleChilledWeightChange = async (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const cookedWeight = Math.round((foodComponent.cookedWeight ?? 0) * (foodComponent.count ?? 0));
		if (Math.abs(cookedWeight - Number(value)) > Number(value) || Math.abs(cookedWeight - Number(value)) > cookedWeight) {
			setChilledWeightError(true);
		} else {
			setChilledWeightError(false);
		}
		await handleWastageChange('chilledWeight', value);
	};

	const handlePreppedWeightChange = async (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const preppedWeight = Math.round((foodComponent.weight ?? 0) * (foodComponent.count ?? 0));
		if (Math.abs(preppedWeight - Number(value)) > Number(value) || Math.abs(preppedWeight - Number(value)) > preppedWeight) {
			setPreppedWeightError(true);
		} else {
			setPreppedWeightError(false);
		}
		await handleWastageChange('preppedWeight', value);
	};

	const StyledMenu = styled((props: MenuProps) => (
		<Menu
			elevation={0}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			{...props}
		/>
	))(({ theme }) => ({
		'& .MuiPaper-root': {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 100,
			color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
			boxShadow:
				'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
			'& .MuiMenu-list': {
				padding: '4px 0'
			},
			'& .MuiMenuItem-root': {
				'& .MuiSvgIcon-root': {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5)
				},
				'&:active': {
					backgroundColor: alpha(theme.palette.primary500, theme.palette.action.selectedOpacity)
				}
			}
		}
	}));

	return (
		<TableRow>
			<TableCell sx={{ fontSize: '17px', fontWeight: 600 }}>{foodComponent.name.en}</TableCell>
			<TableCell sx={{ fontSize: '17px', fontWeight: 600, textAlign: 'center' }}>
				<InputField
					type="number"
					inputMode="numeric"
					disabled={appContext.isOffline}
					value={preppedWeightState}
					placeholder="0"
					onChange={(event) => handlePreppedWeightChange(event)}
					debounce
					inputProps={{ min: 0 }}
					onKeyPress={preventMinus}
					endAdornment={
						<InputAdornment position="end">
							<Box
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									color: preppedWeightError
										? theme.palette.red
										: !foodComponent.kitchenWastageInputs?.preppedWeight || appContext.isOffline
											? theme.palette.neutral600
											: theme.palette.primary500,
									':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
								}}
							>
								g
							</Box>
						</InputAdornment>
					}
					sx={{
						textTransform: 'none',
						fontSize: '16px',
						fontWeight: 600,
						border: '1px solid transparent',
						borderRadius: '4px',
						width: '100px',
						height: '51px',
						textAlign: 'center',
						p: '10px',
						input: { textAlign: 'center' },
						color: preppedWeightError
							? theme.palette.red
							: !foodComponent.kitchenWastageInputs?.preppedWeight || appContext.isOffline
								? theme.palette.neutral600
								: theme.palette.primary500,
						backgroundColor: preppedWeightError
							? theme.palette.secondaryRed50
							: !foodComponent.kitchenWastageInputs?.preppedWeight || appContext.isOffline
								? theme.palette.neutral100
								: theme.palette.primary100,
						':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
					}}
				/>
			</TableCell>
			<TableCell sx={{ fontSize: '17px', fontWeight: 600, textAlign: 'center' }}>
				<InputField
					type="number"
					value={chilledWeightState}
					placeholder="0"
					disabled={appContext.isOffline}
					onChange={(event) => handleChilledWeightChange(event)}
					debounce
					inputMode="numeric"
					inputProps={{ min: 0 }}
					onKeyPress={preventMinus}
					endAdornment={
						<InputAdornment position="end">
							<Box
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									color: chilledWeightError
										? theme.palette.red
										: !foodComponent.kitchenWastageInputs?.chilledWeight || appContext.isOffline
											? theme.palette.neutral600
											: theme.palette.primary500,
									':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
								}}
							>
								g
							</Box>
						</InputAdornment>
					}
					sx={{
						textTransform: 'none',
						fontSize: '16px',
						fontWeight: 600,
						border: '1px solid transparent',
						borderRadius: '4px',
						width: '100px',
						height: '51px',
						textAlign: 'center',
						p: '10px',
						input: { textAlign: 'center' },
						color: chilledWeightError
							? theme.palette.red
							: !foodComponent.kitchenWastageInputs?.chilledWeight || appContext.isOffline
								? theme.palette.neutral600
								: theme.palette.primary500,
						backgroundColor: chilledWeightError
							? theme.palette.secondaryRed50
							: !foodComponent.kitchenWastageInputs?.chilledWeight || appContext.isOffline
								? theme.palette.neutral100
								: theme.palette.primary100,
						':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
					}}
				/>
			</TableCell>
			<TableCell sx={{ fontSize: '17px', fontWeight: 600, textAlign: 'center' }}>
				<InputField
					type="number"
					value={wastageState}
					placeholder="0"
					disabled={appContext.isOffline}
					onChange={(event) => handleWastageChange('wastage', event)}
					debounce
					fullWidth={true}
					inputMode="numeric"
					onKeyPress={preventMinus}
					endAdornment={
						<InputAdornment position="end">
							<Box
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									color: wastageError
										? theme.palette.red
										: !foodComponent.kitchenWastageInputs?.wastage || appContext.isOffline
											? theme.palette.neutral600
											: theme.palette.primary500,
									':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
								}}
							>
								g
							</Box>
						</InputAdornment>
					}
					sx={{
						textTransform: 'none',
						fontSize: '16px',
						fontWeight: 600,
						border: wastageError ? '1px solid red' : '1px solid transparent',
						borderRadius: '4px',
						width: '100px',
						height: '51px',
						textAlign: 'center',
						p: '10px',
						input: { textAlign: 'center' },
						color: wastageError
							? 'red'
							: !foodComponent.kitchenWastageInputs?.wastage || appContext.isOffline
								? theme.palette.neutral600
								: theme.palette.primary500,
						backgroundColor: wastageError
							? theme.palette.secondaryRed50
							: !foodComponent.kitchenWastageInputs?.wastage || appContext.isOffline
								? theme.palette.neutral100
								: theme.palette.primary100,
						':disabled': { color: theme.palette.neutral600, backgroundColor: theme.palette.neutral100 }
					}}
				/>
			</TableCell>
			<TableCell sx={{ fontSize: '17px', fontWeight: 600, textAlign: 'center' }}>
				<div>
					{actionCommentType === 'select' && (
						<Button
							id="demo-customized-button"
							aria-controls={open ? 'demo-customized-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							variant="text"
							disableElevation
							disabled={appContext.isOffline}
							onClick={(event) => {
								!anchorEl && setAnchorEl(event.currentTarget);
							}}
							endIcon={<KeyboardArrowDownIcon />}
							sx={{
								textTransform: 'capitalize',
								fontSize: actionState === '' ? '16px' : '14px',
								fontWeight: 600,
								width: '150px',
								height: '50px',
								color: theme.palette.neutral600,
								':hover': { color: theme.palette.neutral600 }
							}}
						>
							{actionState === '' ? 'Action' : `${actionState}`}
						</Button>
					)}
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							'aria-labelledby': 'demo-customized-button'
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={() => setAnchorEl(null)}
					>
						<MenuItem
							sx={{ fontWeight: 600, py: 0.2 }}
							value={LeftoverAction.waste}
							onClick={() => {
								handleActionChange(LeftoverAction.waste);
								setAnchorEl(null);
							}}
						>
							<Box sx={{ color: actionState === LeftoverAction.waste ? theme.palette.primary500 : 'inherit' }}>Waste</Box>
						</MenuItem>
						<Divider sx={{ my: 0.5 }} />
						<MenuItem
							sx={{ fontWeight: 600, py: 0.2 }}
							value={LeftoverAction.useTomorrow}
							onClick={() => {
								handleActionChange(LeftoverAction.useTomorrow);
								setAnchorEl(null);
							}}
						>
							<Box sx={{ color: actionState === LeftoverAction.useTomorrow ? theme.palette.primary500 : 'inherit' }}>
								Use tomorrow
							</Box>
						</MenuItem>
						<Divider sx={{ my: 0.5 }} />
						<MenuItem
							sx={{ fontWeight: 600, py: 0.2 }}
							value={LeftoverAction.staffMeal}
							onClick={() => {
								handleActionChange(LeftoverAction.staffMeal);
								setAnchorEl(null);
							}}
						>
							<Box sx={{ color: actionState === LeftoverAction.staffMeal ? theme.palette.primary500 : 'inherit' }}>
								Staff meal
							</Box>
						</MenuItem>
						<Divider sx={{ my: 0.5 }} />
						<MenuItem
							sx={{ fontWeight: 600, py: 0.2 }}
							value={LeftoverAction.other}
							onClick={() => {
								setActionCommentType(LeftoverAction.other);
								setAnchorEl(null);
							}}
						>
							<Box
								sx={{
									color:
										!Object.values(LeftoverAction).includes(actionState as LeftoverAction) && actionState
											? theme.palette.primary500
											: 'inherit'
								}}
							>
								Other
							</Box>
						</MenuItem>
					</StyledMenu>
					{actionCommentType === 'Other' && (
						<InputField
							type="text"
							inputMode="text"
							disabled={appContext.isOffline}
							debounce
							fullWidth={true}
							value={actionState}
							onBlur={() => setActionCommentType('select')}
							onChange={(event) => handleActionInputChange(event)}
							sx={{
								color: theme.palette.neutral600,
								backgroundColor: theme.palette.neutral100,
								border: '1px solid ' + theme.palette.neutral600,
								fontWeight: 600,
								width: '150px',
								height: '50px',
								px: 1,
								borderRadius: '5px'
							}}
						/>
					)}
				</div>
			</TableCell>
			<TableCell sx={{ fontSize: '17px', fontWeight: 400 }}>
				{(weightCalculator(
					foodComponent,
					foodComponent.cookedWeight ?? 0,
					foodComponent.count ?? 0,
					foodComponent.cupsQuantity,
					foodComponent.quantities || []
				) +
					(foodComponent.child?.weight || 0)) *
					(foodComponent.purchasingCost || 0)}
			</TableCell>
		</TableRow>
	);
};

export default WastageTableRow;
