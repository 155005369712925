import { sortBy } from 'lodash-es';

import { Card } from '@mui/material';

import { FoodComponent } from '../../../../libs';
import WastageTableRow from '../WastageTableRow';

interface WastageTableProps {
	foodComponents: FoodComponent[];
	date: string;
}

const WastageTable = ({ foodComponents, date }: WastageTableProps) => (
	<>
		{sortBy(foodComponents, 'name.en').map((foodComponent) => (
			<Card
				variant="outlined"
				key={foodComponent.id}
				sx={{
					width: '100%',
					my: 1,
					mx: 1,
					mt: 1,
					minHeight: '130px'
				}}
			>
				<WastageTableRow key={foodComponent.id} date={date} foodComponent={foodComponent} />
			</Card>
		))}
	</>
);
export default WastageTable;
