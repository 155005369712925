import { Chip, Stack } from '@mui/material';
import { startCase } from 'lodash-es';

interface ChipsProps {
	values: string[];
	onDelete: (value: string) => void;
}

export default function Chips({ values, onDelete }: ChipsProps) {
	return (
		<Stack direction={'row'} flexWrap={'wrap'} gap={0.5} rowGap={1}>
			{values.map((value) => {
				return (
					<Chip
						key={value}
						label={startCase(value)}
						onMouseDown={(event) => event.stopPropagation()}
						onDelete={() => onDelete(value)}
					/>
				);
			})}
		</Stack>
	);
}
