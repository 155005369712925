import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../../assets/themes/calo';
import { FoodComponent } from '../../../../libs';
import SectionTableRow from '../SectionTableRow';

interface SectionTableProps {
	foodComponents: FoodComponent[];
}

const SectionTable = ({ foodComponents }: SectionTableProps) => {
	const theme = useTheme();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral50,
			color: theme.palette.common.black,
			border: 'none',
			fontSize: 14,
			fontWeight: 500
		}
	}));

	return (
		<Table>
			<TableHead>
				<TableRow>
					<StyledTableCell sx={{ p: 1, pl: 2, minWidth: '200px' }}>Component</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '50px' }}>Raw Weight</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '50px' }}>Actual Raw Weight</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '50px' }}>Cooked Weight</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '100px' }}>Actual Cooked Weight</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '100px' }}>Leftovers</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '100px' }}>Action</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '100px' }}>Leftover Cost</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{foodComponents.length > 0 ? (
					foodComponents.map((foodComponent) => <SectionTableRow key={foodComponent.id} foodComponent={foodComponent} />)
				) : (
					<TableRow>
						<TableCell
							colSpan={9}
							sx={{
								minWidth: 150,
								p: 1,
								fontFamily: caloTheme.typography.fontFamily,
								fontSize: '28px',
								textTransform: 'uppercase',
								color: theme.palette.neutral600,
								textAlign: 'center',
								border: 'none'
							}}
						>
							No components
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};

export default SectionTable;
