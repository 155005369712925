import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { caloTheme } from '../../../../assets/themes/calo';

export interface FilterDrawerProps {
	children: ReactNode;
	isFilterOpened: boolean;
	setIsFilterOpened: (data: boolean) => void;
}

export default function FiltersDrawer({ children, isFilterOpened, setIsFilterOpened }: FilterDrawerProps) {
	return (
		<Drawer anchor={'right'} open={isFilterOpened} onClose={() => setIsFilterOpened(false)}>
			<Stack direction={'column'} sx={{ width: 440, height: '100%', p: 4 }} rowGap={'20px'} role="presentation">
				<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
					<Typography fontFamily={caloTheme.typography.fontFamily} fontSize={'24px'} fontWeight={'700'}>
						Filters
					</Typography>
					<IconButton
						aria-label="close"
						onClick={() => setIsFilterOpened(false)}
						sx={{
							fontWeight: 600,
							color: 'black'
						}}
					>
						<CloseIcon />
					</IconButton>
				</Stack>
				{children}
			</Stack>
		</Drawer>
	);
}
