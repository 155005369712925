import InfoIcon from '@mui/icons-material/Info';
import {
	Stack,
	styled,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	useTheme
} from '@mui/material';
import { caloTheme } from '../../../../assets/themes/calo';
import { BaggingDispatch } from '../../../../libs';
import { MealViewFilters } from '../MealsView';
import SectionTableRow from '../SectionTableRow';

interface SectionTableProps {
	dispatchedMeals: BaggingDispatch[];
	filters: MealViewFilters;
}

export default function SectionTable({ dispatchedMeals, filters }: SectionTableProps) {
	const theme = useTheme();

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.neutral50,
			color: theme.palette.common.black,
			border: 'none',
			fontSize: 14,
			fontWeight: 500
		}
	}));
	return (
		<Table>
			<TableHead>
				<TableRow>
					<StyledTableCell sx={{ p: 1, pl: 2, minWidth: '200px' }}>Meal</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '50px' }}>Size</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '250px' }}>Container name</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '50px' }}>Total</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '100px' }}>Normal</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '100px' }}>Modified</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '100px' }}>
						<Stack direction={'row'} spacing={1}>
							<Typography fontWeight={500} fontSize={14}>
								Leftovers
							</Typography>
							<Tooltip arrow title="Leftovers are combined for morning and evening">
								<InfoIcon />
							</Tooltip>
						</Stack>
					</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '135px' }}>Total cost</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '135px' }}>Food cost</StyledTableCell>
					<StyledTableCell sx={{ minWidth: '135px' }}>Packaging cost</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{dispatchedMeals.length > 0 ? (
					dispatchedMeals.map((dispatchedMeal, i) => (
						<SectionTableRow key={`${dispatchedMeal.id}-${i}`} dispatchedMeal={dispatchedMeal} filters={filters} />
					))
				) : (
					<TableRow>
						<TableCell
							colSpan={9}
							sx={{
								minWidth: 150,
								p: 1,
								fontFamily: caloTheme.typography.fontFamily,
								fontSize: '28px',
								textTransform: 'uppercase',
								color: theme.palette.neutral600,
								textAlign: 'center',
								border: 'none'
							}}
						>
							No components
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
}
