import { FoodType } from '@calo/types';
import { Checkbox, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, Radio, Select, Stack } from '@mui/material';
import { startCase } from 'lodash-es';
import { useState } from 'react';
import Chips from '../../../../components/Chips';
import DatePicker from '../../../../components/DatePicker';
import { Shift } from '../../../../libs';
import { getDate } from '../../../../libs/utils/helperFunctions';
import FilterButtons from '../../Common/FilterButtons/FilterButtons';
import { MealViewFilters } from '../MealsView';

interface FilterItemsProps {
	areas: string[];
	filters: MealViewFilters;
	setFilters: React.Dispatch<React.SetStateAction<MealViewFilters>>;
	getDefaultFilters: () => MealViewFilters;
	toggleFilersDrawer: (bool: boolean) => void;
}

export default function FilterItems({ areas, filters, setFilters, getDefaultFilters, toggleFilersDrawer }: FilterItemsProps) {
	const minDate = new Date('01-01-2024');
	const [startDate, setStartDate] = useState(new Date(filters.startDate));
	const [endDate, setEndDate] = useState(new Date(filters.endDate));
	const [selectedShift, setSelectedShift] = useState<string>(filters.shift);
	const [selectedMealTypes, setSelectedMealTypes] = useState<FoodType[]>(filters.mealTypes);
	const [selectedCities, setSelectedCities] = useState<string[]>(filters.cities);
	const [onlySandwiches, setOnlySandwiches] = useState(filters.onlySandwiches);

	function onApplyFilters() {
		setFilters({
			...filters,
			startDate: startDate ? getDate(startDate) : '',
			endDate: endDate ? getDate(endDate) : '',
			shift: selectedShift,
			mealTypes: selectedMealTypes,
			cities: selectedCities,
			onlySandwiches
		});
		toggleFilersDrawer(false);
	}
	function onClearFilters() {
		setFilters(getDefaultFilters());
		toggleFilersDrawer(false);
	}

	return (
		<Stack sx={{ height: '100%' }} direction={'column'} justifyContent={'space-between'}>
			<Stack direction={'column'} spacing={3.5}>
				<DatePicker
					minDate={minDate}
					date={startDate}
					setDate={(newDate) => newDate && setStartDate(newDate)}
					label={'Start Date'}
					showCalendarIcon={true}
				/>
				<DatePicker
					minDate={minDate}
					date={endDate}
					setDate={(newDate) => newDate && setEndDate(newDate)}
					label={'End Date'}
					showCalendarIcon={true}
				/>
				<FormControl fullWidth>
					<InputLabel>Shift</InputLabel>
					<Select
						label="Shift"
						value={selectedShift}
						onChange={(event) => setSelectedShift(event.target.value)}
						renderValue={(shift) => {
							return startCase(shift);
						}}
					>
						{Object.keys(Shift).map((shift) => (
							<MenuItem key={shift} value={shift}>
								<FormControlLabel value={shift} control={<Radio checked={selectedShift === shift} />} label={startCase(shift)} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel>{selectedCities.length === 0 ? 'Select Cities types' : 'Cities'}</InputLabel>
					<Select
						label={selectedCities.length === 0 ? 'Select Cities types' : 'Cities'}
						value={selectedCities}
						multiple
						onChange={(e) => setSelectedCities(e.target.value as string[])}
						renderValue={(cities) => {
							return (
								<Chips
									values={cities}
									onDelete={(city) => {
										setSelectedCities(selectedCities.filter((selectedCity) => city !== selectedCity));
									}}
								/>
							);
						}}
					>
						{areas?.map((area) => (
							<MenuItem key={area} value={area}>
								<Checkbox checked={selectedCities.includes(area)} />
								<ListItemText primary={startCase(area)} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel>{selectedMealTypes.length === 0 ? 'Select Meal types' : 'Meal types'}</InputLabel>
					<Select
						label={selectedMealTypes.length === 0 ? 'Select Meal types' : 'Meal types'}
						value={selectedMealTypes}
						multiple
						onChange={(e) => setSelectedMealTypes(e.target.value as FoodType[])}
						renderValue={(mealTypes) => {
							return (
								<Chips
									values={mealTypes}
									onDelete={(mealType) => {
										setSelectedMealTypes(selectedMealTypes.filter((selectedMealType) => selectedMealType !== mealType));
									}}
								/>
							);
						}}
					>
						{Object.values(FoodType).map((typeValue) => (
							<MenuItem key={typeValue} value={typeValue}>
								<Checkbox checked={selectedMealTypes.includes(typeValue)} />
								<ListItemText primary={startCase(typeValue)} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel>Only Sandwiches</InputLabel>
					<Select
						label="Only Sandwiches"
						placeholder="Select sandwitches"
						value={onlySandwiches ? 1 : 0}
						onChange={(e) => setOnlySandwiches(Boolean(e.target.value))}
						renderValue={(val) => {
							return <>{val ? 'Yes' : 'No'}</>;
						}}
					>
						<MenuItem value={1}>
							<FormControlLabel value={1} control={<Radio checked={onlySandwiches} />} label="Yes" />
						</MenuItem>
						<MenuItem value={0}>
							<FormControlLabel value={0} control={<Radio checked={!onlySandwiches} />} label="No" />
						</MenuItem>
					</Select>
				</FormControl>
			</Stack>
			<FilterButtons onClearFilters={onClearFilters} onApplyFilters={onApplyFilters} />
		</Stack>
	);
}
