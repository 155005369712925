import { format } from 'date-fns/fp';
import { BaggingDispatch } from '../../libs';

export const getDefaultDates = (date: string) => {
	return {
		startDate: format('yyyy-MM-dd')(Date.parse(date)),
		endDate: format('yyyy-MM-dd')(Date.parse(date))
	};
};

export const computePackagingCost = (dispatchedMeal: BaggingDispatch) => {
	return dispatchedMeal.packagingCost ?? dispatchedMeal.packages?.reduce((acc, curr) => acc + (curr?.cost ?? 0), 0) ?? 0;
};
