import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ExportFoodComponentWastageMutation } from '../libs';
import { EXPORT_FOOD_COMPONENT_WASTAGE_MUTATION } from '../libs/graphQL';
import { createAutoDownloadLinkFromFile } from '../libs/utils';
import { ComponentsLeftoverMetricsFilters } from '../views/LeftoverMetrics/ComponentsView/ComponentsView';

export interface ExportComponentParams {
	filters: ComponentsLeftoverMetricsFilters;
	kitchen: string;
}

export default function useExportFoodComponentsWastage({ filters, kitchen }: ExportComponentParams) {
	const [downloadFoodComponentWastage, { loading }] = useMutation<ExportFoodComponentWastageMutation>(
		EXPORT_FOOD_COMPONENT_WASTAGE_MUTATION,
		{
			onCompleted: (data) => {
				if (!data.exportFoodComponentWastage?.data) {
					return;
				}
				createAutoDownloadLinkFromFile(
					data.exportFoodComponentWastage.data,
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					'xlsx',
					`Calo_Food_Components_Leftover_Metrics_${kitchen}_${filters.startDate}-${filters.endDate}`
				);
			},
			onError: (error) => {
				toast.error(error.message);
			}
		}
	);

	return { downloadFoodComponentWastage, loading };
}
