import { compact, sortBy } from 'lodash-es';
import { useContext, useEffect, useRef, useState } from 'react';

import { Dictionary } from '@calo/types';
import { Box, Drawer } from '@mui/material';

import { AppContext } from '../../../App';
import EmptyMessage from '../../../components/EmptyMessage';
import { Food, FoodComponent, Kitchen, Session } from '../../../libs';
import FilterPopup from './FilterPopup';
import MealStatusMobileRow from './MealStatusMobileRow';

interface MealStatusMobileListProps {
	foods: Food[];
	kitchen: Kitchen;
	foodComponents: Dictionary<FoodComponent>;
	shift: Session;
}

const MealStatusMobileList = ({ shift, kitchen, foodComponents, foods }: MealStatusMobileListProps) => {
	const appContext = useContext(AppContext);

	const [filters, setFilters] = useState({
		showCustom: true,
		shift: shift
	});
	const filtersRef = useRef<any>();
	useEffect(() => {
		setFilters({ ...filters, shift: shift });
	}, [shift]);
	useEffect(() => {
		if (appContext.isFilterOpen) {
			filtersRef.current?.open();
		} else {
			filtersRef.current?.close();
		}
	}, [filtersRef.current]);

	const shiftValues = filters.shift === Session.evening ? [Session.evening, Session.earlyMorning] : [filters.shift];

	const filteredFoods = filters.showCustom
		? foods.filter((food) => !food.isCustom && shiftValues.some((shift) => food.deliveryTime?.includes(shift)))
		: foods.filter((food) => food.deliveryTime?.includes(shift));

	return (
		<>
			<Box display={'flex'} flexDirection={'column'} sx={{ my: 2, mr: 1 }}>
				{filteredFoods.length > 0 ? (
					sortBy(filteredFoods, 'name.en').map((food) => (
						<MealStatusMobileRow
							food={food}
							key={food.id}
							foodComponents={compact(food.componentIds?.map((id) => foodComponents[`${food.date}#${id}`]))}
						/>
					))
				) : (
					<EmptyMessage label={'No data for the selected filters'} />
				)}
			</Box>
			<Drawer anchor="bottom" open={appContext.isFilterOpen} onClose={() => appContext.setIsFilterOpen!(false)}>
				<FilterPopup
					filters={filters}
					setFilters={(v) => {
						setFilters(v);
						appContext.setIsFilterOpen(false);
					}}
					filtersRef={filtersRef}
					kitchen={kitchen}
				/>
			</Drawer>
		</>
	);
};

export default MealStatusMobileList;
