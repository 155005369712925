import { debounce } from 'lodash';
import { sortBy } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Brand } from '@calo/types';
import { Autocomplete, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AppContext } from '../../App';
import EmptyMessage from '../../components/EmptyMessage';
import { FoodComponent, Options, SingleFoodComponent } from '../../libs';
import { GET_FOOD_COMPONENT_QUERY, LIST_FOOD_COMPONENTS_BY_NAME_QUERY } from '../../libs/graphQL';
import Export from './Export';
import RecipePage from './RecipePage';
import { caloTheme } from '../../assets/themes/calo';

interface RecipeGuideBookProps {
	kitchen: string;
	date: string;
}

const RecipeGuideBook = ({ kitchen, date }: RecipeGuideBookProps) => {
	const [selectedComponent, setSelectedComponent] = useState<SingleFoodComponent | null>(null);
	const [selectedComponentId, setSelectedComponentId] = useState('');
	const [newCookedWeight, setNewCookedWeight] = useState(1);
	const [nameInput, setNameInput] = useState('');

	const appContext = useContext(AppContext);

	const [fetchData, { data: foodComponentsList }] = useLazyQuery(LIST_FOOD_COMPONENTS_BY_NAME_QUERY);

	useEffect(() => {
		// Fetch data on component mount
		fetchData({ variables: { name: '', kitchen, brand: Brand.CALO } });
	}, [fetchData, kitchen]);

	const debouncedSearch = useCallback(
		debounce((query) => {
			fetchData({ variables: { name: query, kitchen, brand: Brand.CALO } });
		}, 300),
		[kitchen]
	);

	const isNewComponent = () => {
		if (!selectedComponentId) {
			return true;
		}

		const componentsFromSearch = foodComponentsList.listFoodComponentsByName.data as FoodComponent[];

		return !componentsFromSearch.map((comp) => comp.id).includes(selectedComponentId);
	};

	useEffect(() => {
		if (nameInput && isNewComponent()) {
			debouncedSearch(nameInput);
		}

		return () => debouncedSearch.cancel();
	}, [nameInput, debouncedSearch]);

	const { data: foodComponent, loading } = useQuery(GET_FOOD_COMPONENT_QUERY, {
		variables: { id: selectedComponentId },
		skip: !selectedComponentId,
		onError: (e) => toast.error(e.message)
	});

	useEffect(() => {
		if (foodComponent) {
			setSelectedComponent(foodComponent?.getFoodComponent);
		}
	}, [foodComponent]);

	const selectChildComponent = (compId: string, name: string) => {
		setSelectedComponentId(compId);
		setNameInput(name);
	};

	const options: Options<SingleFoodComponent>[] = useMemo(
		() =>
			sortBy(foodComponentsList?.listFoodComponentsByName?.data || [], (f) => `${f.name.en}`).map((foodComponent) => ({
				name: foodComponent.name.en,
				value: foodComponent.id,
				data: foodComponent,
				label: `${foodComponent.name.en}`
			})),
		[kitchen, foodComponentsList]
	);

	useEffect(() => {
		setSelectedComponent(null);
	}, [kitchen, date]);

	return (
		<Box sx={{ width: appContext.isSidebarOpened ? '98%' : '100%' }}>
			<Stack direction={'row'} sx={{ mx: '25px' }} justifyContent="space-between" alignItems="center" spacing={2}>
				<Autocomplete
					id="component-list-values"
					value={{
						name: nameInput,
						value: selectedComponent?.id || '',
						data: selectedComponent,
						label: nameInput
					}}
					options={options}
					onInputChange={(event, val) => {
						setNameInput(val);
						setSelectedComponentId('');
					}}
					sx={{ width: '90%' }}
					onChange={(event, val) => {
						setSelectedComponentId(val?.value || '');
						setSelectedComponent(null);
						setNameInput(val?.name || '');
					}}
					renderInput={(params) => <TextField {...params} placeholder="Component Name" />}
					renderOption={(props, option) => (
						<li {...props}>
							{option.label}{' '}
							{option.data && option.data.prototype && (
								<Typography variant="subtitle1" sx={{ fontSize: '12px', color: caloTheme.palette.primary.main }}>
									&nbsp;{'(draft)'}
								</Typography>
							)}
						</li>
					)}
				/>
				<Export
					selectedComponent={selectedComponent && selectedComponent}
					isDownloadDisabled={!selectedComponent}
					newCookedWeight={newCookedWeight}
					date={date}
				/>
			</Stack>
			{selectedComponent ? (
				<Box sx={{ display: 'flex', direction: 'row', maxWidth: '190%' }}>
					<Stack sx={{ mx: '15px', my: '5px', maxWidth: '100%' }}>
						{selectedComponent.prototype && (
							<Typography variant="subtitle1" sx={{ paddingX: '16px', fontSize: '12px', color: caloTheme.palette.primary.main }}>
								Draft Component
							</Typography>
						)}
						<RecipePage
							newCookedWeight={newCookedWeight}
							setNewCookedWeight={setNewCookedWeight}
							selectedFoodComponent={selectedComponent}
							selectChildComponent={selectChildComponent}
						/>
					</Stack>
				</Box>
			) : loading ? (
				<EmptyMessage label={'Loading...'} />
			) : (
				<EmptyMessage label={'Please select a component'} />
			)}
		</Box>
	);
};
export default RecipeGuideBook;
