import { Box, Stack } from '@mui/material';
import { BaggingDispatch } from '../../../../libs';
import { MealViewFilters } from '../MealsView';
import SectionTable from '../SectionTable';

interface SectionPageProps {
	dispatchedMeals: BaggingDispatch[];
	filters: MealViewFilters;
}

export default function SectionPage({ dispatchedMeals, filters }: SectionPageProps) {
	return (
		<Stack direction="column" sx={{ mt: '10px', mb: '5px', width: '100%' }}>
			<Box sx={{ width: '100%' }}>
				<SectionTable filters={filters} dispatchedMeals={dispatchedMeals && dispatchedMeals.length > 0 ? dispatchedMeals : []} />
			</Box>
		</Stack>
	);
}
