import { useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Icon } from '@iconify/react';
import { Card, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Modal from '../../../../components/Modal';
import PortionActionButton from '../../../../components/PortionActionButton/PortionActionButton';
import { Food, FoodPortioningFoodType, Page, Session, UpdateFoodPortioningStatusOperation } from '../../../../libs';
import { UPDATE_FOOD_PORTIONING_STATUS_MUTATION } from '../../../../libs/graphQL';
import { getFoodPortioningStatusAttributes, isValidURL } from '../../../../libs/utils/helperFunctions';
import AssemblyStatusItem from '../../AssemblyStatusItem/AssemblyStatusItem';
import ProcessTime from '../../ProcessTime';

interface AssemblyCheckRowMobileProps {
	food: Food;
	foodType: FoodPortioningFoodType;
	shift: string;
}

const AssemblyCheckRowMobile = ({ food, foodType, shift }: AssemblyCheckRowMobileProps) => {
	const theme = useTheme();
	const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false);
	const [mutateFoodPortioningStatus, { loading }] = useMutation(UPDATE_FOOD_PORTIONING_STATUS_MUTATION);
	const shiftValues = shift === Session.evening ? [Session.evening, Session.earlyMorning] : [shift];

	const portioningStatus = food.portioningStatus?.find((s) => shiftValues.includes(s.session as Session));
	const attributes = getFoodPortioningStatusAttributes(portioningStatus!, foodType, Page.assemblyCheck, theme);

	const handleUpdateFoodPortioningStatus = async (operation: UpdateFoodPortioningStatusOperation) => {
		if (!loading) {
			await mutateFoodPortioningStatus({
				variables: {
					id: food.id,
					session: shift,
					kitchen: food.kitchen,
					operation: operation
				},
				onError: (e) => {
					toast.error(e.message);
				}
			});
		}
	};

	return (
		<Card
			variant="outlined"
			sx={{
				width: '100%',
				my: 1,
				mx: 1,
				mt: 1,
				minHeight: '130px',
				borderColor: attributes.buttonText === 'Finished' ? theme.palette.primary500 : theme.palette.neutral200
			}}
		>
			<Stack display={'flex'} flexDirection={'column'} justifyContent={'space-between'} sx={{ m: 1 }}>
				<Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
					<Typography fontWeight="600" fontSize="19px" textTransform={'capitalize'}>
						{food.name.en}
					</Typography>
					<IconButton
						onClick={() => {
							food.assemblyURL && window.open(food.assemblyURL, '_blank');
						}}
						disabled={!food.assemblyURL || !isValidURL(food.assemblyURL)}
						sx={{ color: '#367bf5', marginRight: '9px' }}
					>
						<Icon icon="ph:file-text-duotone" width="1.2em" height="1.3em" />
					</IconButton>
				</Stack>
				<Stack flexDirection="row" gap="10px">
					<AssemblyStatusItem attributes={attributes} />
					{attributes.time && <ProcessTime attributes={attributes} portioningStatus={portioningStatus} time={attributes.time!} />}
				</Stack>
			</Stack>
			<PortionActionButton
				attributes={attributes}
				handleUpdateFoodPortioningStatus={handleUpdateFoodPortioningStatus}
				loading={loading}
				isMobile={true}
			/>
			<Modal
				loading={loading}
				open={isConfirmationModalOpened}
				setOpen={(isOpened: boolean) => setIsConfirmationModalOpened(isOpened)}
				handleClose={() => setIsConfirmationModalOpened(false)}
				handleAccept={() => handleUpdateFoodPortioningStatus(UpdateFoodPortioningStatusOperation.notReadyToBePortioned)}
				title={'Uncheck ' + food.name.en}
				message={'Are you sure you want to uncheck ' + food.name.en + '?'}
			/>
		</Card>
	);
};

export default AssemblyCheckRowMobile;
