import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import { Link, PathMatch } from 'react-router-dom';
import { caloTheme } from '../../assets/themes/calo';
import { KDSPermission } from '../../libs';
import { RoutePaths } from '../../libs/enums';
import NotificationSidebar from '../NotificationSidebar/NotificationSidebar';
import { NotificationReq } from './Sidebar';

export interface Route {
	title: string;
	route: RoutePaths;
	permission: KDSPermission;
	isActive: PathMatch<string> | null;
}
interface RouteLinkProps {
	route: Route;
	notificationReq: NotificationReq;
	isIndent?: boolean;
}
const RouteLink = ({ route, notificationReq, isIndent }: RouteLinkProps) => {
	return (
		<Link className="link" to={route.route}>
			<ListItemText
				sx={{
					m: 0,
					py: 2,
					pr: '2px',
					pl: isIndent ? '50px' : '12px',
					...(route.isActive ? { backgroundColor: '#E6F3EC', color: 'black' } : {})
				}}
			>
				<Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', justifyContent: 'space-between' }}>
					<Typography sx={{ width: '80%', fontFamily: caloTheme.typography.fontFamily }}>{route.title}</Typography>
					{(route.route === RoutePaths.lockTimeChanges || route.route === RoutePaths.logisticsChanges) && (
						<Box display={'flex'} flexDirection={'row'} sx={{ justifyContent: 'end', ml: '1px', mt: '-4px' }}>
							{route.title === 'Kitchen' ? (
								<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
									<NotificationSidebar
										requestLabel="customer"
										requestNumber={notificationReq.lockTimeNotificationReq.customerRequest}
									/>
									<NotificationSidebar requestLabel="cx" requestNumber={notificationReq.lockTimeNotificationReq.cxRequest} />
								</Stack>
							) : (
								<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
									<NotificationSidebar
										requestLabel="customer"
										requestNumber={notificationReq.logisticsNotificationReq.customerRequest}
									/>
									<NotificationSidebar requestLabel="cx" requestNumber={notificationReq.logisticsNotificationReq.cxRequest} />
								</Stack>
							)}
						</Box>
					)}
					{route.title === 'Shortage Display' && (
						<Box display={'flex'} flexDirection={'row'} sx={{ justifyContent: 'end', ml: '1px', mt: '-4px' }}>
							<NotificationSidebar requestLabel="alert" requestNumber={notificationReq.shortagesNotificationReq} />
						</Box>
					)}
				</Stack>
			</ListItemText>
		</Link>
	);
};

export default RouteLink;
