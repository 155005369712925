import { v4 as uuid } from 'uuid';

import { CognitoUserAmplify } from '@aws-amplify/ui';

import { KDSPermission } from '../enums';
import { CognitoUser } from '../interfaces';
import { Country, Kitchen } from '../types';

export const parseCognitoUser = (user?: CognitoUserAmplify): CognitoUser => {
	const permissions = user && user.attributes ? user?.attributes['custom:permissions'] : '';
	const group = user && user.attributes ? user?.attributes['custom:group'] : '';
	const userCountry = user && user.attributes ? (user?.attributes['custom:country'] as Country) : Country.BH;
	const filteredKitchens = Object.values(Kitchen).filter((k) => !k.includes('000'));
	const kitchenStaffPermissions = Object.values(KDSPermission).filter((p) => !p.includes(KDSPermission.VIEW_QUALITY_METRICS));

	return {
		id: user?.attributes?.sub || user?.attributes?.email || uuid(),
		name: user?.attributes?.name ?? '',
		email: user?.attributes?.email ?? '',
		phoneNumber: user?.attributes?.phone_number ?? '',
		country: userCountry,
		kitchen:
			user && user.attributes && user?.attributes['custom:kitchen']
				? ((user?.attributes['custom:kitchen'] as string).split(';') as Kitchen[])
				: [filteredKitchens.find((k) => k.includes(userCountry)) || Kitchen.BH001],
		group: group || '',
		permissions: permissions ? (permissions.split('|') as KDSPermission[]) : kitchenStaffPermissions
	};
};

export const getUserAttributes = (user: CognitoUser): Partial<CognitoUser> => {
	return {
		id: user.id,
		name: user.name,
		email: user.email
	};
};
