import React, { useState } from 'react';

import { Icon as Iconify } from '@iconify/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../assets/themes/calo';
import { Delivery, Gift, Session, SpecialRequestFilterProps } from '../../../libs';
import Export from '../Export';
import SpecialRequestTableRow from './SpecialRequestTableRow';

interface SpecialRequestTableProps {
	date: string;
	kitchen: string;
	deliveryList: Delivery[];
	filters: SpecialRequestFilterProps;
	specialRequestList: Gift[];
	setFilters: (data: SpecialRequestFilterProps) => void;
	zones: (string | undefined)[];
}

const SpecialRequestTable = ({ specialRequestList, filters, setFilters, deliveryList, zones }: SpecialRequestTableProps) => {
	const theme = useTheme();
	const [zoneAnchorEl, setZoneAnchorEl] = useState<null | HTMLElement>(null);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			color: theme.palette.common.black,
			border: 'none',
			fontSize: '14px',
			fontWeight: 600,
			lineHeight: '14px'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const handleZoneClick = (event: React.MouseEvent<HTMLElement>) => {
		setZoneAnchorEl(event.currentTarget);
	};

	const handleZoneClose = (zone?: string) => {
		setZoneAnchorEl(null);
		setFilters({ ...filters, zone: zone === 'All' ? undefined : zone });
	};

	return (
		<Box sx={{ width: '100%', minHeight: '100vh' }}>
			<Stack display="flex" flexDirection={'row'} justifyContent={'space-between'} sx={{ m: 2 }}>
				<Stack display="flex" flexDirection={'row'} justifyContent={'start'} sx={{ my: 'auto', ml: '4px' }}>
					<Typography sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '19px', fontWeight: 600 }}>
						Request List
					</Typography>
				</Stack>
				<Stack display="flex" flexDirection={'row'} justifyContent={'end'} sx={{ mx: '-12px' }}>
					<Stack display="flex" flexDirection={'row'}>
						<Button
							onClick={handleZoneClick}
							variant="outlined"
							endIcon={<ArrowDropDownIcon />}
							sx={{ textTransform: 'capitalize', fontSize: '16px', mr: 2, maxHeight: '60px' }}
						>
							{filters.zone || 'All'}
						</Button>
						<Menu anchorEl={zoneAnchorEl} open={Boolean(zoneAnchorEl)} onClose={() => handleZoneClose()}>
							<MenuItem
								onClick={() => handleZoneClose('All')}
								sx={{ fontWeight: 600, backgroundColor: filters.zone === undefined ? theme.palette.primary50 : 'transparent' }}
							>
								All
							</MenuItem>
							{zones.map((zone) => (
								<MenuItem
									key={zone}
									onClick={() => handleZoneClose(zone)}
									sx={{
										fontWeight: 600,
										backgroundColor: filters.zone === zone ? theme.palette.primary50 : 'transparent'
									}}
								>
									{zone}
								</MenuItem>
							))}
						</Menu>
					</Stack>
					<Stack
						display="flex"
						flexDirection={'row'}
						sx={{
							border: 2,
							borderRadius: 2,
							mr: 2,
							width: '130',
							height: '48px',
							borderColor: caloTheme.palette.neutral100
						}}
					>
						<Button
							onClick={() => setFilters({ ...filters, session: Session.earlyMorning })}
							sx={{
								borderRadius: '8px',
								m: '3px',
								backgroundColor: filters.session === Session.earlyMorning ? caloTheme.palette.secondaryYellow100 : null,
								color:
									filters.session === Session.earlyMorning ? caloTheme.palette.secondaryYellow500 : caloTheme.palette.neutral400
							}}
						>
							<Iconify icon="tabler:sunrise" width={22} />
						</Button>
						<Button
							onClick={() => setFilters({ ...filters, session: Session.morning })}
							sx={{
								borderRadius: '8px',
								m: '3px',
								backgroundColor: filters.session === Session.morning ? caloTheme.palette.secondaryOrange100 : null,
								color: filters.session === Session.morning ? caloTheme.palette.secondaryOrange500 : caloTheme.palette.neutral400
							}}
						>
							<Iconify icon="f7:sun-max" width={22} />
						</Button>
						<Button
							onClick={() => setFilters({ ...filters, session: Session.evening })}
							sx={{
								borderRadius: '8px',
								m: '3px',
								backgroundColor: filters.session === Session.evening ? caloTheme.palette.secondaryBlue100 : null,
								color: filters.session === Session.evening ? caloTheme.palette.secondaryBlue500 : caloTheme.palette.neutral400
							}}
						>
							<Iconify icon="solar:moon-line-duotone" width={22} />
						</Button>
					</Stack>
					<Stack>
						<Export
							shift={filters.session}
							date={filters.date.gte}
							kitchen={filters.kitchen}
							gifts={specialRequestList}
							deliveryList={deliveryList}
							isDownloadDisabled={specialRequestList.length === 0 || deliveryList.length === 0}
						/>
					</Stack>
				</Stack>
			</Stack>

			{specialRequestList && deliveryList && (
				<Table sx={{ m: 1, width: '100%' }}>
					<TableHead>
						<TableRow style={{ backgroundColor: caloTheme.palette.neutral50 }}>
							<StyledTableCell sx={{ width: '14%' }} style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
								Username
							</StyledTableCell>
							<StyledTableCell sx={{ width: '12%', textAlign: 'center' }}>Phone Number</StyledTableCell>
							<StyledTableCell sx={{ width: '9%', textAlign: 'center' }}>Driver Name</StyledTableCell>
							<StyledTableCell sx={{ width: '8%', textAlign: 'center' }}>Invoice</StyledTableCell>
							<StyledTableCell sx={{ width: '10%', textAlign: 'center' }}>Requested By</StyledTableCell>
							<StyledTableCell sx={{ width: '14%', textAlign: 'center' }}>Requested Date</StyledTableCell>
							<StyledTableCell sx={{ width: '14%', textAlign: 'center' }}>Zone</StyledTableCell>
							<StyledTableCell sx={{ width: '23%', textAlign: 'center' }}>Request Type</StyledTableCell>
							<StyledTableCell sx={{ width: '7%', textAlign: 'center' }}>Comment</StyledTableCell>
							<StyledTableCell
								sx={{ width: '7%', textAlign: 'center' }}
								style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
							>
								Attachment
							</StyledTableCell>
						</TableRow>
					</TableHead>
					{specialRequestList && specialRequestList.length === 0 ? (
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={9}
									sx={{
										p: 1,
										fontFamily: caloTheme.typography.fontFamily,
										fontSize: '28px',
										textTransform: 'capitalize',
										color: theme.palette.neutral600,
										textAlign: 'center',
										border: 'none'
									}}
								>
									No Requests
								</TableCell>
							</TableRow>
						</TableBody>
					) : (
						<TableBody>
							{specialRequestList.map((request) => (
								<SpecialRequestTableRow
									request={request}
									key={`${request.id}-${request.updatedAt}`}
									delivery={deliveryList.find((delivery) => delivery.id === request.deliveryId) || undefined}
								/>
							))}
						</TableBody>
					)}
				</Table>
			)}
		</Box>
	);
};
export default SpecialRequestTable;
