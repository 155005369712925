import { compact } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import { DeliveryTicketWithIndex, DocsType } from '../../../libs';
import { EXPORT_DOCUMENTS_MUTATION, UPDATE_DELIVERY_TICKETS_DOWNLOADED_AT_MUTATION } from '../../../libs/graphQL';
import { createAutoDownloadLinkFromArrayOfUrls } from '../../../libs/utils/helperFunctions';
import InvoiceCard from './InvoiceCard';

interface ExportProps {
	deliveryTickets: DeliveryTicketWithIndex[];
}

const getDeliveriesMap = (deliveryTickets: DeliveryTicketWithIndex[], selectedCards: string[]) =>
	compact(deliveryTickets.filter((item) => selectedCards.includes(item.id)).map((item) => item.data?.deliveryId));

const Export = ({ deliveryTickets }: ExportProps) => {
	const [isOpened, setIsOpened] = useState(false);
	const [selectedCards, setSelectedCards] = useState(deliveryTickets.map((item) => item.id));
	const [selectedAllCards, setSelectedAllCards] = useState(false);
	const [isInvoiceSelected, setIsInvoiceSelected] = useState(true);
	const [isFrontLabelSelected, setIsFrontLabelSelected] = useState(false);
	const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
	const [errorMessageTimeoutId, setErrorMessageTimeoutId] = useState<number | null>(null);
	const theme = useTheme();
	const appContext = useContext(AppContext);
	const [exportDocuments, { loading }] = useMutation(EXPORT_DOCUMENTS_MUTATION);
	const [shiftMenuAnchorEl, setShiftMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [typeMenuAnchorEl, setTypeMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isShiftMenuOpened = Boolean(shiftMenuAnchorEl);
	const isTypeMenuOpened = Boolean(typeMenuAnchorEl);
	const [shiftFilter, setShiftFilter] = useState('all');
	const [typeFilter, setTypeFilter] = useState('all');
	const [downloadFilter, setDownloadFilter] = useState('all');
	const [downloadedTypeFiltersMenuAnchorEl, setDownloadedTypeFiltersMenuAnchorEl] = useState<null | HTMLElement>(null);
	const isDownloadedFilterMenuOpened = Boolean(downloadedTypeFiltersMenuAnchorEl);
	const [updateDeliveryTicketsDownloadedAt, { loading: isLoading }] = useMutation(UPDATE_DELIVERY_TICKETS_DOWNLOADED_AT_MUTATION);

	const handleSelectedSwaps = (id: string, isSelected: boolean) => {
		if (isSelected) {
			setSelectedCards(selectedCards.filter((item) => item !== id));
		} else {
			setSelectedCards([...selectedCards, id]);
		}
	};

	const handleSelectAll = () => {
		if (selectedAllCards) {
			setSelectedAllCards(false);
		} else {
			setSelectedAllCards(true);
		}
	};

	useEffect(() => {
		if (selectedAllCards) {
			deliveryTickets
				.filter(
					(delivery) =>
						(typeFilter === 'all' ? delivery : delivery.data?.type === typeFilter) &&
						(shiftFilter === 'all' ? delivery : delivery.data?.deliveryTime === shiftFilter) &&
						(downloadFilter === 'all'
							? delivery
							: downloadFilter === 'downloaded'
								? delivery.downloadedAt
								: !delivery.downloadedAt)
				)
				.map((ticket) => setSelectedCards((old) => [...old, ticket.id]));
		} else {
			setSelectedCards([]);
		}
	}, [selectedAllCards]);

	useEffect(() => {
		setSelectedCards([]);
		setSelectedAllCards(false);
	}, [shiftFilter, typeFilter]);

	const handleExportInvoices = async () => {
		const ids = getDeliveriesMap(deliveryTickets, selectedCards);
		const docsTypes: DocsType[] = [];
		if (isInvoiceSelected) {
			docsTypes.push(DocsType.invoice);
		}
		if (isFrontLabelSelected) {
			docsTypes.push(DocsType.frontLabel);
		}
		const downloaded = await exportDocuments({
			variables: { ids, docTypes: docsTypes },
			onCompleted: (data) => {
				if (data?.exportDocuments.data && data?.exportDocuments.data.length > 0) {
					createAutoDownloadLinkFromArrayOfUrls(data.exportDocuments.data);
					setSelectedAllCards(false);
				} else {
					toast.error('Something went wrong');
				}
			},
			onError: (e) => {
				toast.error(e.message);
			}
		});
		if (downloaded) {
			await updateDeliveryTicketsDownloadedAt({
				variables: { ids: selectedCards, kitchen: deliveryTickets[0].kitchen },
				onError: (e: any) => {
					console.log(e);
				}
			});
		}
		setIsOpened(false);
	};

	const handleDownloadType = (type: DocsType) => {
		const isActionInvalid =
			(type === DocsType.invoice && isInvoiceSelected && !isFrontLabelSelected) ||
			(type === DocsType.frontLabel && !isInvoiceSelected && isFrontLabelSelected);
		if (isActionInvalid) {
			setIsErrorMessageDisplayed(true);
			const timeoutId = setTimeout(() => {
				setIsErrorMessageDisplayed(false);
			}, 10_000) as unknown as number;
			setErrorMessageTimeoutId(timeoutId);
		} else if (type === DocsType.invoice) {
			setIsInvoiceSelected((old) => !old);
			setIsErrorMessageDisplayed(false);
			errorMessageTimeoutId && clearTimeout(errorMessageTimeoutId);
		} else if (type === DocsType.frontLabel) {
			setIsFrontLabelSelected((old) => !old);
			setIsErrorMessageDisplayed(false);
			errorMessageTimeoutId && clearTimeout(errorMessageTimeoutId);
		}
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Stack direction="row" justifyContent="end" alignItems="end" spacing={2} sx={{ mr: 4 }}>
				<LoadingButton
					loading={loading || isLoading || isOpened}
					disabled={deliveryTickets.length === 0 || appContext.isOffline}
					onClick={() => setIsOpened(true)}
					variant="outlined"
					sx={{
						textTransform: 'capitalize',
						fontSize: '19px',
						lineHeight: '23px',
						fontWeight: 600,
						borderWidth: 2,
						width: '295px',
						p: '14px',
						borderRadius: '8px',
						borderColor: theme.palette.primary500,
						':hover': { borderWidth: 2, borderColor: theme.palette.primary400 }
					}}
					endIcon={
						<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M14 10.5V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V10.5"
								stroke={
									deliveryTickets.length === 0 || appContext.isOffline ? theme.palette.neutral500 : theme.palette.primary500
								}
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M4.66675 7.16663L8.00008 10.5L11.3334 7.16663"
								stroke={
									deliveryTickets.length === 0 || appContext.isOffline ? theme.palette.neutral500 : theme.palette.primary500
								}
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M8 10.5V2.5"
								stroke={
									deliveryTickets.length === 0 || appContext.isOffline ? theme.palette.neutral500 : theme.palette.primary500
								}
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					}
				>
					Download Invoices & Labels
				</LoadingButton>
			</Stack>
			<Dialog
				open={isOpened}
				fullWidth
				maxWidth={'lg'}
				scroll="paper"
				onClose={() => {
					setIsOpened(false);
					setSelectedAllCards(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" sx={{ textAlign: 'start', mt: 1, pb: 0 }}>
					<Box sx={{ fontFamily: caloTheme.typography.fontFamily, fontSize: '28px', lineHeight: '40px' }}>
						Download invoices & Labels
					</Box>
					<Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ mt: 2 }}>
						<Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} sx={{ mb: 1 }}>
							<Button
								onClick={(event) => {
									!typeMenuAnchorEl && setTypeMenuAnchorEl(event.currentTarget);
								}}
								variant="outlined"
								endIcon={<ArrowDropDownIcon />}
								sx={{ textTransform: 'capitalize', fontSize: '16px', mr: 2 }}
							>
								{typeFilter ? typeFilter : 'All'}
								<Select
									id="demo-simple-select"
									value={typeFilter}
									open={isTypeMenuOpened}
									onClose={() => setTypeMenuAnchorEl(null)}
									label="session"
									sx={{ visibility: 'hidden', width: 0, height: 0 }}
								>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'all'}
										onClick={() => {
											setTypeFilter('all');
											setTypeMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>All</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'meal'}
										onClick={() => {
											setTypeFilter('meal');
											setTypeMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Meal Swap</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'manual'}
										onClick={() => {
											setTypeFilter('manual');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Manual Delivery</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'other'}
										onClick={() => {
											setTypeFilter('other');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Other</Box>
									</MenuItem>
								</Select>
							</Button>

							<Button
								onClick={(event) => {
									!shiftMenuAnchorEl && setShiftMenuAnchorEl(event.currentTarget);
								}}
								variant="outlined"
								endIcon={<ScheduleIcon />}
								sx={{ textTransform: 'capitalize', fontSize: '16px' }}
							>
								{shiftFilter}
								<Select
									id="demo-simple-select"
									value={shiftFilter}
									open={isShiftMenuOpened}
									onClose={() => setShiftMenuAnchorEl(null)}
									label="session"
									sx={{ visibility: 'hidden', width: 0, height: 0 }}
								>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'all'}
										onClick={() => {
											setShiftFilter('all');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>All</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'earlyMorning'}
										onClick={() => {
											setShiftFilter('earlyMorning');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Early Morning Shift</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'morning'}
										onClick={() => {
											setShiftFilter('morning');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Morning Shift</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'evening'}
										onClick={() => {
											setShiftFilter('evening');
											setShiftMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Evening Shift</Box>
									</MenuItem>
								</Select>
							</Button>
							<Button
								onClick={(event) => {
									!downloadedTypeFiltersMenuAnchorEl && setDownloadedTypeFiltersMenuAnchorEl(event.currentTarget);
								}}
								variant="outlined"
								endIcon={<ArrowDropDownIcon />}
								sx={{ textTransform: 'capitalize', fontSize: '16px' }}
							>
								{downloadFilter}
								<Select
									id="demo-simple-select"
									value={downloadFilter}
									open={isDownloadedFilterMenuOpened}
									onClose={() => setDownloadedTypeFiltersMenuAnchorEl(null)}
									label="session"
									sx={{ visibility: 'hidden', width: 0, height: 0 }}
								>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'all'}
										onClick={() => {
											setDownloadFilter('all');
											setDownloadedTypeFiltersMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>All</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'downloaded'}
										onClick={() => {
											setDownloadFilter('downloaded');
											setDownloadedTypeFiltersMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Downloaded</Box>
									</MenuItem>
									<MenuItem
										sx={{ fontWeight: 600 }}
										value={'not Downloaded'}
										onClick={() => {
											setDownloadFilter('not Downloaded');
											setDownloadedTypeFiltersMenuAnchorEl(null);
										}}
									>
										<Box sx={{ px: '15px' }}>Not Downloaded</Box>
									</MenuItem>
								</Select>
							</Button>
						</Stack>
						<Stack direction="row" justifyContent="space-between" alignItems="start" spacing={0} sx={{ mb: 1 }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={isInvoiceSelected}
										onChange={() => handleDownloadType(DocsType.invoice)}
										inputProps={{ 'aria-label': 'controlled' }}
										sx={{ pr: 2 }}
									/>
								}
								label="Invoice"
								sx={{
									pr: 1,
									mr: 3,
									border: `1px solid ${isInvoiceSelected ? theme.palette.primary100 : theme.palette.neutral800}`,
									backgroundColor: isInvoiceSelected ? theme.palette.primary100 : 'transparent',
									borderRadius: '8px'
								}}
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={isFrontLabelSelected}
										onChange={() => handleDownloadType(DocsType.frontLabel)}
										inputProps={{ 'aria-label': 'controlled' }}
										sx={{ pr: 3 }}
									/>
								}
								label="Label"
								sx={{
									pr: 1,
									mr: 0,
									border: `1px solid ${isFrontLabelSelected ? theme.palette.primary100 : theme.palette.neutral800}`,
									backgroundColor: isFrontLabelSelected ? theme.palette.primary100 : 'transparent',
									borderRadius: '8px'
								}}
							/>
						</Stack>
					</Stack>
					<IconButton
						aria-label="close"
						onClick={() => {
							setIsOpened(false);
							setSelectedAllCards(false);
						}}
						sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent sx={{ width: '100%', mt: '-10px' }}>
					<Stack direction="column" justifyContent="space-evenly" alignItems="center" spacing={2} sx={{ mb: 1 }}>
						<Fade in={isErrorMessageDisplayed}>
							<Box sx={{ color: theme.palette.red, fontSize: '20px' }}>
								{isErrorMessageDisplayed ? 'Both checkboxes cannot be unchecked' : ''}
							</Box>
						</Fade>
						{deliveryTickets
							.filter(
								(delivery) =>
									(typeFilter === 'all' ? delivery : delivery.data?.type === typeFilter) &&
									(shiftFilter === 'all' ? delivery : delivery.data?.deliveryTime === shiftFilter) &&
									(downloadFilter === 'all'
										? delivery
										: downloadFilter === 'downloaded'
											? delivery.downloadedAt
											: !delivery.downloadedAt)
							)
							.map((deliveryTicket) => (
								<InvoiceCard
									key={deliveryTicket.id}
									deliveryTicket={deliveryTicket}
									isSelected={!selectedCards.includes(deliveryTicket.id)}
									setIsSelected={(id: string, isSelected: boolean) => handleSelectedSwaps(id, isSelected)}
								/>
							))}
					</Stack>
				</DialogContent>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ m: 3, mt: 0 }}>
					<LoadingButton
						loading={loading}
						variant="outlined"
						sx={{
							textTransform: 'capitalize',
							fontSize: '19px',
							borderRadius: '8px',
							lineHeight: '23px',
							fontWeight: 600,
							px: '20px',
							py: '14px',
							borderColor: theme.palette.primary500,
							':hover': { borderWidth: 2, borderColor: theme.palette.primary400 }
						}}
						onClick={handleSelectAll}
					>
						{selectedAllCards ? 'Unselect All' : 'Select All'}
					</LoadingButton>

					<LoadingButton
						loading={loading}
						variant="contained"
						disabled={selectedCards.length === 0 || appContext.isOffline}
						sx={{
							textTransform: 'capitalize',
							fontSize: '19px',
							borderRadius: '8px',
							lineHeight: '23px',
							color: 'white',
							fontWeight: 600,
							px: '20px',
							py: '14px'
						}}
						onClick={handleExportInvoices}
					>
						Download
					</LoadingButton>
				</Stack>
			</Dialog>
		</Box>
	);
};
export default Export;
