import { Button, Stack } from '@mui/material';
import { caloTheme } from '../../../../assets/themes/calo';

interface FilterButtonsProps {
	onClearFilters: () => void;
	onApplyFilters: () => void;
}

export default function FilterButtons({ onApplyFilters, onClearFilters }: FilterButtonsProps) {
	return (
		<Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2} width={'100%'}>
			<Button variant="outlined" sx={{ width: '50%' }} onClick={onClearFilters}>
				clear filter
			</Button>
			<Button variant="contained" sx={{ color: caloTheme.palette.white, width: '50%' }} onClick={onApplyFilters}>
				apply
			</Button>
		</Stack>
	);
}
